.slider-container {

	.slick-slide {
		outline: 0 none;
	}

	//== Arrows
	& {
		.slick-arrow {
			@include btn-reset;
			padding: $grid-gutter-width/2;
			margin: 0;
			color: $slider-arrow-color;

			svg {
				display: inline-block;
				fill: currentColor;
				vertical-align: middle;
			}

			&:hover {
				color: color-themify();
			}
		}

		.slick-disabled {
			cursor: default;
			&, &:hover {
				color: $slider-arrow-disabled-color;
			}
		}

		.slick-next,
		.slick-prev {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			height: 50%;
			width: $slider-padding-for-arrow;

		}
		.slick-next {
			right: -$slider-padding-for-arrow;
			text-align: right;
		}
		.slick-prev {
			left: -$slider-padding-for-arrow;
			text-align: left;
		}

		@media (min-width: $screen-sm-min) and (max-width: $container-desktop + $slider-padding-for-arrow*2 + $grid-gutter-width) {
			padding-left: $slider-padding-for-arrow;
			padding-right: $slider-padding-for-arrow;
		}
	}

	//== Dots
	.slick-dots {
		@include ul-reset;
		display: block;
		text-align: center;
		padding: 0;
		margin-top: 25px;

		li {
			display: inline-block;
			width: $slider-dot-item-width;
			height: $slider-dot-item-width;
			vertical-align: top;
			text-align: center;
		}

		button {
			vertical-align: middle;
			cursor: pointer;
			display: inline-block;
			@include btn-reset;
			border: 0 none;
			padding: 0;
			text-indent: -9999px;
			font-size: 0;
			line-height: $slider-dot-item-width;
			width: $slider-dot-width;
			height: $slider-dot-width;
			border-radius: $slider-dot-border-radius;
			transition: transition-base(color);

			background-color: $slider-dot-color;

			&:hover {
				background-color: $slider-dot-hover-color;
			}
		}

		.slick-active {
			button {
				cursor: default;
				background-color: $slider-dot-active-color;
			}
		}

		@include media(mobile){
			margin-top: 20px;
		}

		@include h-all{
			color: inherit;
		}
	}
}

.slider-featured {
	$slide-zindex-link-overlay: 3;
	$slide-padding-horizontal: 30px;
	$slide-padding-horizontal-xs: 15px;
	$slide-animate-duration: .4s;
	$slide-footer-height: 140px;
	$slide-footer-height-xs: 100px;

	.slick-wrap {
		@include padding-column-reset();
		@include media(mobile) {
			@include margin-LR(-10px, -10px);
		}
	}

	.slick-slide {
		&, &:focus {
			outline: 0 none;
		}
	}

	%slide-base {
		margin: $grid-gutter-width/2;
		position: relative;
		height: 370px;
		overflow: hidden;
		@include media(mobile) {
			width: 215px;
			height: 285px;
			margin: 10px;
		}
	}

	.slide-content {
		color: $c-white;
		font-size: 16px;
		@include media(mobile) {
			font-size: 14px;
		}

		@extend %slide-base;
		& {
			display: flex;
			flex-direction: column;
			align-content: flex-end;
			.slide-body {
				margin-top: auto;
			}
		}
		.slide-heading {
			@include start-animate(slideInUp, $slide-animate-duration);
		}
		.slide-img,
		.slide-footer,
		.slide-body {
			transition: transform $slide-animate-duration;
		}
		.slide-body {
			transform: translateY($slide-footer-height);
		}
		.slide-footer {
			height: $slide-footer-height;
			transform: translateY($slide-footer-height);
		}
		@include media(mobile) {
			.slide-body {
				transform: translateY($slide-footer-height-xs);
			}
			.slide-footer {
				height: $slide-footer-height-xs;
				transform: translateY($slide-footer-height-xs);
			}
			.slide-img {

			}
		}

		&:hover {
			.slide-heading {
				margin-top: -5px;
				transition: margin-top .2s;
			}
			.slide-body {
				position: relative;
			}
			.slide-img {
				transform: translateY(-$slide-footer-height);
				@include media(mobile) {
					transform: translateY(-$slide-footer-height-xs);
				}
			}
			.slide-body,
			.slide-footer {
				transform: none;
			}

		}
	}

	.slide-img {
		background: no-repeat center top;
		-webkit-background-size: cover;
		background-size: cover;

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(#151515, .76);
			z-index: -1;
		}
	}

	.slide-footer,
	.slide-body {
		@include padding-LR($slide-padding-horizontal, $slide-padding-horizontal);
		@include padding-TB(20px, 20px);
		@include media(mobile) {
			padding: $slide-padding-horizontal-xs;
		}
	}

	//Footer
	.slide-footer {
		font-size: 18px;
		@include media(mobile) {
			font-size: 14px;
		}
		background-color: color-themify();

		@include padding-TB(20px, 25px);
		@include media(mobile) {
			padding: $slide-padding-horizontal-xs;
		}
		&-content {
			overflow: hidden;
			height: 100%;
		}
	}

	.slide-meta {
		max-height: 3.2em;
		overflow: hidden;
		.date {
			white-space: nowrap;
			+ .category {
				position: relative;
				&:before {
					content: "|";
					color: rgba($c-white, .2);
					padding: 0 10px;
				}
			}
		}

		.category {
			color: color-themify();
			a {
				position: relative;
				z-index: 3;
				@include link-border-hover();
			}
		}
	}

	.slide-title {
		font-weight: $font-weight-medium;
		font-size: inherit;
		margin: 0;
		margin-top: 15px;
		position: relative;
		z-index: $slide-zindex-link-overlay + 1;
		overflow: hidden;
		color: inherit;

		max-height: 4.5em;
		@include media(mobile) {
			margin-top: 10px;
		}
	}

	//Link Overlay
	.slide-link-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: $slide-zindex-link-overlay;
		text-indent: -9999px;
		opacity: 0;
	}

}