.page-search-view {
	.header {
		.navbar-toggle-search,
		.form-search {
			display: none;
		}
	}

	@include media(mobile) {
		.page-title {
			text-align: center;
		}
	}
}

.view-search-index-view {

	.view-content {
		margin-top: 60px;
		margin-bottom: 60px;
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}

	.search-item {
		padding-bottom: 35px;
		margin-top: 30px;
		border-bottom: 1px solid rgba(#c3c5c5, .3);

		&:first-child {
			margin-top: 0;
		}
	}

	.title {
		margin-bottom: 0;
		@extend %h6;
		font-weight: $font-weight-semibold;
		color: color-themify(link);
		a {
			@include link-border-hover();
		}
	}

	@include media(mobile) {
		.search-item {
			padding-bottom: 20px;
			margin-top: 30px;
		}

		.view-content {
			margin-top: 30px;
		}
	}
}


.search-form-block {

	.views-widget-filter-search_api_views_fulltext {
		//.auto_submit.form-text.form-autocomplete
	}

}

.search-top-block {

	@include margin-TB(20px, 20px);
	font-size: $text-small-font-size;
	color: #888f90;

	@include media(tablet-desktop) {
		display: flex;
		justify-content: space-between;
	}
	@include padding-column-reset();

	.search-filters-wrapper,
	.view-header {
		@include padding-column();

	}

	.view-header {
	}

	.search-filters-wrapper {
		display: flex;
	}
	.search-filters-text {
		display: inline-block;
		margin-right: 10px;
	}
	ul.search-filters {
		@include ul-reset;
	}

	.search-filters-item {
		display: inline-block;
		&.active {
			a {
				cursor: default;
				pointer-events: none;
				&, &:hover {
					color: inherit;
					border-bottom-color: transparent;
				}

			}
		}

		a {
			@include link-border-hover();
		}

		+ .search-filters-item {
			&:before {
				content: "|";
				padding: 0 10px;
				color: #e6ebed;
			}
		}
	}

	@include media(mobile) {
		font-size: 12px;
		@include margin-TB(10px, 10px);
		.search-filters-wrapper,
		.view-header {
			@include margin-TB(10px, 10px);
		}
		.search-filters-item {
			+ .search-filters-item {
				&:before {
					@include padding-LR(5px, 5px);
				}
			}
		}
	}
}




