@mixin link-reset() {
	text-decoration: none;

	&:hover, &:focus {
		text-decoration: none;
	}
}

@mixin btn-reset {
	background: none;
	border: 0 none;
	box-shadow: none;
	&, &:focus {
		outline: none;
	}
}

@mixin ul-reset() {
	margin: 0;
	padding: 0;

	li {
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
	}
}

@mixin row-flex($isWrap: false) {
	display: flex;

	@if ($isWrap == true) {
		flex-wrap: wrap;
	}
}

@mixin fade-hover($color: color('link'), $opacity: .8) {
	&, &:hover {
		color: $color;
	}
	opacity: $opacity;
	transition: $transition-default;
	&:hover {
		opacity: 1;
	}
}

@mixin color-fade-hover($color, $opacity: .5) {
	&, &:hover {
		color: $color;
	}
	opacity: $opacity;

	&:hover {
		transition: $transition-default;
		opacity: 1;
	}
}

@mixin padding-column($gutter: $grid-gutter-width) {
	padding-left: ceil(($gutter / 2));
	padding-right: floor(($gutter / 2));
}

@mixin padding-column-reset($gutter: $grid-gutter-width) {
	margin-left: ceil(($gutter / -2));
	margin-right: floor(($gutter / -2));
}

@mixin data-collapsed {
	[data-collapsed="false"] {
		display: inline-block;
		vertical-align: middle;
	}
	[data-collapsed="true"] {
		display: none;
	}

	&.collapsed {
		[data-collapsed="false"] {
			display: none;
		}
		[data-collapsed="true"] {
			display: inline-block;
			vertical-align: middle;
		}
	}
}


@mixin row-flex($wrap: false) {
	display: flex;

	//reset
	&:after, &:before {
		display: none;
	}
	> div {
		float: none;
	}

	@if $wrap == true {
		flex-wrap: wrap;
	}
}

@function transition-base($name, $delay: 0s) {
	@return $name $transition-default $delay;
}

@mixin h-all() {
	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		@content;
	}
}

%fade-hover {
	opacity: 0.8;
	@include transition($transition-fade);
	&:hover {
		opacity: 1;
	}
}

%icon-hover {
	opacity: 0.5;
	@include transition($transition-fade);
	&:hover {
		opacity: 1;
	}
}

%link-hover {
	&:hover {
		color: color-themify('linkHover');
	}
}



