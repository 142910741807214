/**
 * Header
 * --------------------------------------------------
 */

ul.menu {
	@include ul-reset;
	li {
		margin: 0;
		padding: 0;
	}
}

//== Toggle Btn
.navbar-toggle {
	margin: 0;
	color: color(gray, dark);

	&:active,
	&:hover {
		color: color-themify();
	}

	@include data-collapsed;

	transition: color $transition-default;

	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	z-index: $zindex-navbar - 1;
	line-height: $line-height-computed;
	@include padding-column;

	.svg-icon {
		height: auto;
		&-menu {
			width: 34px;
		}
		&-close {
			width: 24px;
		}
		@include media(mobile) {
			&-close,
			&-menu {
				width: 20px;
			}
		}
	}

	html.menu-open & {
		$delay: .3s;
		opacity: 0;
		z-index: $zindex-navbar + 1;
		@include color-fade-hover($nav-sidebar-color, .5);
		transition: transition-base(z-index, $delay), $transition-fade, transition-base(margin-right, $delay);
		//@include start-animate(zoomIn, .4s, $delay);

		text-align: right;

		//margin-right: -$grid-gutter-width/2 !important;

		@include media(tablet) {
			width: $nav-sidebar-title-height;
			height: $nav-sidebar-title-height;
		}
		@include media(mobile) {
			width: $nav-sidebar-xs-title-height;
			height: $nav-sidebar-xs-title-height;
		}
	}
}

//== Toggle Lang
.navbar-switch-lang {
	text-transform: uppercase;
	font-size: 18px;
	.language-switcher-locale-url {
		@include ul-reset;
		@include clearfix;
		li {
			&:first-child, &.en-adt {
				// huck for drupal
				display: none;
			}
			float: left;
			margin-right: 10px;
			+ li {

			}
		}
		.language-link {
			display: inline-block;
			vertical-align: middle;
		}
	}
}

//== Search
.navbar-search {
	color: $c-white;
	@include margin-TB(0, 0);

	.form-text {
		height: $navbar-height;
		background-color: transparent;
		font-size: 20px;
		color: inherit;
		@include placeholder-style(rgba($c-white, .5));
		@include padding-LR(40px, 50px);
		&,
		&:focus {
			border-color: transparent;
		}
	}
	.ajax-progress {
		display: block !important;
	}
	.views-submit-button {
		right: auto;
		left: 0;
		color: inherit;
		opacity: .5;
		&:before {
			right: auto;
			left: 0;
		}
	}

	@include media(tablet) {

		.form-text {

			height: $navbar-height-item-sm;
			padding-left: $nav-sidebar-padding-left + 30px;
		}

		.views-submit-button {
			&:before {
				left: $nav-sidebar-padding-left;
			}
		}
	}

	@include media(mobile) {
		.form-text {
			font-size: 14px;
			height: $navbar-height-item-xs;
			padding-left: $nav-sidebar-xs-padding-horizontal + 30px;
		}

		.views-submit-button {
			&:before {
				left: $nav-sidebar-padding-horizontal-xs;
				font-size: 16px;
				margin-top: -2px;
			}
		}
	}

	#autocomplete {
		@include media(tablet-mobile) {
			display: none !important;
		}
		$padding-horizontal: 40px;
		border-color: $dropdown-border;
		background-color: $dropdown-bg;
		box-shadow: none;
		padding: 30px $padding-horizontal;

		&:before {
			display: block;
			content: "Search Results";
			margin-bottom: 20px;
			font-size: $font-size-base;
			color: rgba($dropdown-color, .5);
			font-weight: $font-weight-regular;
			@include media(mobile) {
				font-size: $font-size-xs-base;
			}
		}

		ul {
			width: auto;
			max-height: none;
			overflow: visible;
			@include margin-LR(-$padding-horizontal, -$padding-horizontal);
		}

		li {
			color: $dropdown-link-color;
			padding: 15px $padding-horizontal;
			height: auto;
			line-height: 1;
			border-bottom-color: $dropdown-border !important;
			margin: 0;

			&:focus,
			&:active,
			&.active,
			&.selected,
			&:hover {
				color: $dropdown-link-hover-color;
				background-color: rgba($c-black, .1) !important;
			}
		}
	}

	@include media(desktop) {
		.container {
			height: 100%;
		}
		.form-search {
			position: absolute;
			right: 0;
			left: 100%;
			top: 0;
			height: 100% !important;
			opacity: 0;
			background-color: color-themify('header');
			@include transition(left .3s, opacity 0s);
			display: none;

			&.in {
				display: block;
				opacity: 1;
				left: 0;
				//.form-group {
				//	@include transition(transform .3s, opacity 0s);
				//	@include animated(.3s);
				//	@extend .slideInRight;
				//}
			}
		}
	}

	@include media(tablet-mobile) {
		border-bottom: 1px solid $nav-sidebar-divider-bg;
		.container {
			padding: 0;
			height: auto;
		}
	}
}

//== Toggle Search
.navbar-toggle-search {
	position: relative;
	z-index: 2;
	display: block;
	padding: 0 $navbar-padding-horizontal;
	margin: 0;
	//margin-right: -($navbar-padding-horizontal);
	height: $navbar-height;

	font-size: 20px;
	color: inherit;
	@include fade-hover(.5);
	@include data-collapsed;
	@include media(tablet-mobile) {
		display: none;
	}
}

//== Backdrop
.navbar-backdrop {
	display: none;

	z-index: $zindex-navbar -1;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

	background-color: $navbar-backdrop;

	html.menu-open & {
		display: block;
		//@extend .fadeIn;
		@include start-animate(fadeIn, .3s);
	}
}

.header {
	@include media(desktop) {
		.container-fluid {
			width: $container-desktop;
			max-width: 100%;
			@include margin-LR(auto, auto);
		}
	}

	@include media(tablet-mobile) {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		max-width: 100%;
		min-width: 320px;
		z-index: $zindex-navbar - 1;
		box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
	}
}

/**
 * Navbar top
 * --------------------------------------------------
 */
.header-navbar-top {
	& {
		margin: 0;
		background-color: $header-top-bg;
		color: $header-top-color;

		.navbar-content {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: center;
			align-items: center;
			height: $header-top-height;
			@include padding-column-reset;
			@include padding-TB($header-top-padding-vertical, $header-top-padding-vertical);
			transition: padding-top $transition-default, padding-bottom $transition-default;

			@include media(tablet) {
				padding-right: $header-top-height;
			}

			@include media(mobile) {
				padding-right: $header-top-height-xs;
				height: $header-top-height-xs;
				@include padding-TB($header-top-padding-vertical-xs, $header-top-padding-vertical-xs);
			}
		}

		a {
			color: $header-top-link-color;
			&:hover {
				color: $header-top-link-hover-color;
			}
		}

		.navbar-left {
			flex: auto 1;
			display: flex;
			align-items: center;
			padding-right: 15px;
		}

		@include media(tablet-mobile) {
			.navbar-name,
			.navbar-switch-lang {
				display: none;
			}
		}
	}

	.navbar-logo,
	.navbar-name,
	.navbar-switch-lang,
	.navbar-phone {
		line-height: $line-height-computed;
		@include padding-column;
	}

	//== phone
	.navbar-phone {
		text-transform: uppercase;
		font-weight: $font-weight-medium;
		font-size: 20px;
		white-space: nowrap;

		position: relative;
		padding-left: 30px + $grid-gutter-width/2;
		.fa {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: $grid-gutter-width/2;
		}
		.fa, a {
			color: color(gray, darkest);
			&:hover {
				color: lighten(color(gray, darkest), 15%);
				border-bottom-color: transparent;
			}
		}

		@include media(mobile) {
			font-size: 12px;
			padding-left: $grid-gutter-width;
		}
	}

	//== logo
	.navbar-logo {
		margin-right: 6%;
		@extend .logo;
		display: block;
		box-sizing: content-box;
		position: relative;

		width: 200px;
		@include media(tablet-desktop) {
			top: -6px; //huck align text
		}
		@include media(mobile) {
			width: 120px;
			
			img {
				max-height: 40px;
			}
		}
		@include media(mobile-xs) {
			width: 85px;
		}
	}

	//== Toggle Lang
	.navbar-switch-lang {
		.language-switcher-locale-url {

			li.first, li.en-adt {
				//huck for drupal
				display: none;
			}

			li {
				margin-right: 0;
				&:after {
					content: "|";
					color: #dedede;
					padding: 0 15px;
				}

				&:last-child {
					&:after {
						display: none;
					}
				}

			}
			.language-link {
				color: #505252;
				font-weight: $font-weight-regular;
				&.active {
					color: color-themify();
				}
			}
		}
	}

	@media (min-width: $screen-desktop) and (max-width: 1040px) {
		.navbar-logo {
			width: 150px;
			margin-right: 0;
		}
		.navbar-switch-lang,
		.navbar-name {
			font-size: 16px;
		}
	}
}

/**
 * Navbar Collapse
 * --------------------------------------------------
 */
.header-navbar-collapse {
	background-color: color-themify('header');
	padding: 0;
	position: relative;
	color: $c-white;
	@include media(desktop) {
		.navbar-content {
			display: flex;
			justify-content: space-between;
		}
	}

	//== Sidebar
	& {

		//== Title
		.navbar-title {
			display: none;
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			z-index: 2;
			background-color: color-themify();
			text-transform: $nav-sidebar-title-text-transform;
			font-size: $nav-sidebar-title-font-size;
			line-height: $nav-sidebar-title-height;
			font-weight: inherit;
			border-bottom: 1px solid $navbar-divider-color;

			@include padding-LR($nav-sidebar-padding-left, $nav-sidebar-toggle-width);
			@include media(tablet-mobile) {
				display: block;
			}
			@include media(mobile) {
				@include padding-LR($nav-sidebar-xs-padding-horizontal, $nav-sidebar-toggle-xs-width);

				line-height: $nav-sidebar-xs-title-height;
				font-size: $nav-sidebar-xs-title-font-size;
			}
		}

		@include media(tablet-mobile) {
			z-index: $zindex-navbar + 1;
			position: fixed;
			top: 0;
			right: 0;
			transform: translateX(100%);

			html.menu-open & {
				$delay: .3s;
				z-index: $zindex-navbar;
				transform: translateX(0);
				transition: z-index .5s $delay, transform .3s;
			}

			max-width: 100%;

			padding: 0;

			font-weight: $nav-sidebar-font-weight;
			color: $nav-sidebar-color;

			height: 100%;
			overflow: hidden;
			width: $nav-sidebar-width;
			font-size: $nav-sidebar-font-size;

			.container-fluid {
				margin-top: $nav-sidebar-title-height;
				@include padding-LR(0, 0);
				height: calc(100% - #{$nav-sidebar-title-height});
				overflow-y: auto;
				padding-bottom: 30px;
			}
		}

		@include media(tablet) {
			//reset
			.navbar-right {
				margin: 0;
				float: none !important;
			}
		}

		@include media(mobile) {
			width: $nav-sidebar-xs-width;
			font-size: $nav-sidebar-xs-font-size;
			.container-fluid {
				margin-top: $nav-sidebar-xs-title-height;
				height: calc(100% - #{$nav-sidebar-xs-title-height});
			}
		}
	}

	//== Caret
	.caret {
		position: relative;
		z-index: 1;

		//reset
		display: none;
		cursor: pointer;
		border: 0 none;
		margin: 0;

		width: $navbar-caret-width;
		height: auto;

		&,
		.svg-icon {
			transition: transition-base(color), transition-base(transform), $transition-fade;
		}

		.svg-icon {
			position: absolute;
			z-index: 2;
			top: 50%;
			right: 20px;
			margin-top: -7px;
			@extend %icon-hover;

			@include media(mobile) {
				width: 14px;
				height: auto;
				right: 10px;
				margin-top: -4px;
			}
		}

		&:hover {
			.svg-icon {
				opacity: 1;
			}
		}
		@include media(tablet-mobile) {
			display: block;
		}
	}

	//== Toggle Lang
	.navbar-switch-lang {
		display: none;

		@include media(tablet-mobile) {
			display: block;

			@include padding-TB(($nav-sidebar-link-vertical + 10px), ($nav-sidebar-link-vertical + 10px));
			@include padding-LR($nav-sidebar-padding-left, $nav-sidebar-padding-right);

			.language-switcher-locale-url {
				margin-left: -15px; //reset padding for language-link

				.language-link {
					font-size: 20px;
					font-weight: inherit;
					color: rgba($c-white, .5);
					border-radius: 46px;
					background-color: transparent;
					padding: 0 15px;
					height: 46px;
					line-height: 48px;
					text-align: center;
					min-width: 46px;
					vertical-align: middle;
					&:hover {
						color: $c-white;
					}
				}
				.active .language-link {
					background-color: $c-white;
					&,
					&:hover {
						color: color-themify('header');
					}
				}
				@include media(mobile) {
					.language-link {
						font-size: 14px;
						border-radius: 31px;
						min-width: 31px;
						height: 31px;
						line-height: 33px;
					}
				}
			}
		}

		@include media(mobile) {
			padding: 20px $nav-sidebar-padding-horizontal-xs;

			.language-switcher-locale-url {
				.language-link {
					min-width: 31px;
					height: 31px;
					line-height: 33px;
				}
			}
		}
	}
}

.header_menu {
	$menu-link-color: $c-white;
	$menu-link-color-hover: rgba(#010101, .5);
	$menu-link-color-active: rgba($c-white, .5);

	li.company-links {
		display: none;
	}

	.fa {
		margin-left: 10px;
		&:first-child {
			@include margin-LR(0, 10px);
		}
	}

	a, .caret {
		transition: transition-base(color), transition-base(background-color);
	}

	ul.menu {
		display: none;
		> li,
		> li > a {
			display: block;
		}
	}

	> ul.menu {
		display: block;
		margin-bottom: 0;
		padding-left: 0; // Override default ul/ol
		list-style: none;
		@include clearfix;

		@include media(desktop) {
			//rewrite padding for first item
			float: left;
			margin-left: -$grid-gutter-width/2;
			> li:first-child > a {
				padding-left: $grid-gutter-width/2;
			}

			.caret {
				display: none;
			}

			> li {
				float: left;
			}
		}

		> li {
			position: relative;
			display: block;

			> a,
			> span {
				position: relative;
				display: block;
				color: $menu-link-color;
				padding: 13px 20px;
			}

			> a {
				&:hover,
				&:focus {
					text-decoration: none;
					color: $menu-link-color-hover;
				}
			}

			&.active,
			&.active-trail {
				> a, > span {
					color: $menu-link-color-active;
					cursor: default;
				}
				> a {
					&:hover,
					&:focus {
						color: $menu-link-color-active;
					}
				}
			}

			//icon for risk compass
			&:nth-child(3) > a {
				&:before {
					content: $fa-var-compass;
					@include fa-icon;
					margin-right: 10px;
					font-size: 22px;
					@include media(mobile) {
						font-size: 14px;
					}
				}
			}

			@include media(tablet-mobile) {
				border-bottom: 1px solid rgba(255, 255, 255, .3);
				> a {
					font-size: inherit;
					font-weight: inherit;
					text-align: left;
					color: inherit;

					padding: $nav-sidebar-link-vertical $nav-sidebar-padding-left;
					padding-right: $nav-sidebar-padding-right;
				}

				&:not(.active):not(.active-trail) {
					&.open > a:hover,
					> a:focus,
					> a:hover {
						color: $c-white;
						&,
						+ .caret {
							background-color: color-themify('linkHover');
						}
					}
				}

				&.open {
					background-color: color-themify('linkHover');

					.caret {
						.svg-icon {
							transform: rotate(180deg);
						}
					}

					&.active-trail > a,
					&.active > a {
						color: rgba($c-white, .5);
						&,
						+ .caret {
							background-color: transparent;
						}
					}
				}

				&.expanded {
					display: flex;
					flex-wrap: wrap;

					> a, > span:first-child {
						flex: 1 $navbar-caret-width;
					}

				}
			}
			@include media(mobile) {
				> a {
					line-height: 20px;
					padding: $nav-sidebar-link-vertical-xs $nav-sidebar-padding-horizontal-xs;
				}
			}
		}
	}

	li.expanded {
		%dropdown-menu-custom {
			margin-top: 0;
			padding-top: 0;
			padding-bottom: 0;
			border-radius: 0;
			border: 0 none;

			> li > a,
			> li > span {
				font-weight: $font-weight-light;
				white-space: normal;
				line-height: 1.4;
				padding: $dropdown-link-padding;
			}

			@include media(desktop) {
				min-width: 320px;
				opacity: 0;
				@include transition($transition-fade);
			}
			> li > a {
				&:focus,
				&:hover {
					background-color: $dropdown-link-hover-bg;
				}
			}
			@include media(tablet-mobile) {
				width: 100% !important;
				> li {
					border-bottom: 1px solid $dropdown-divider-bg;
				}
				> li > a,
				.dropdown-header {
					font-size: inherit;
					font-weight: inherit;
				}
			}
			@include media(mobile) {
				> li > a,
				.dropdown-header {
					font-size: $nav-sidebar-xs-font-size;
					font-weight: inherit;
				}
			}
		}
		%dropdown-menu-open-custom {
			@include media(desktop) {
				opacity: 1;
			}
			> li.active-trail > a,
			> li.active > a {
				&, &:hover {
					cursor: default;
					color: $dropdown-link-active-color;
					background-color: $dropdown-link-active-bg;
				}
			}
			@include media(tablet-mobile) {
				background-color: $dropdown-bg !important;
				box-shadow: none !important;

				> li > a,
				.dropdown-header {
					width: 100%;
					padding: $nav-sidebar-link-vertical $nav-sidebar-padding-left;
					padding-right: $nav-sidebar-padding-right;
				}
				> li:not(.active):not(.active-trail) > a {
					&:hover,
					&:focus {
						color: inherit;
						text-decoration: none;
						background-color: $dropdown-xs-link-hover-bg;
					}
				}
			}

			@include media(tablet) {
				position: static;
				float: none;
				width: auto;
				margin-top: 0;
				background-color: transparent;
				border: 0;
				box-shadow: none;
			}
			@include media(mobile) {
				> li > a,
				.dropdown-header {
					line-height: 20px;
					padding: $nav-sidebar-link-vertical-xs $nav-sidebar-padding-horizontal-xs;
				}
			}
		}

		@extend .dropdown;

		> ul.menu {
			display: none;
			opacity: 0;
			transition: transition-base(opacity);
			@extend .dropdown-menu;
			@extend %dropdown-menu-custom;
			margin-top: 0 !important;

			> a,
			> span {
				font-weight: 300;
				white-space: normal;
				line-height: 1.4;
				padding: 18px 20px;
			}
		}
		&.open {
			> ul.menu {
				display: block;
				opacity: 1;
				@extend %dropdown-menu-open-custom;
				@include media(tablet-mobile) {
					position: relative;
				}
				&:before {
					content: "";
					position: absolute;
					height: 1px;
					background-color: $navbar-divider-color;
					top: -1px;
					width: 100%;
				}
			}
		}
	}
}























