//== Padding / Margin
@mixin padding-TB($top,$bottom) {
	padding-top: $top;
	padding-bottom: $bottom;
}

@mixin padding-LR($left,$right) {
	padding-left: $left;
	padding-right: $right;
}

@mixin margin-TB($top,$bottom) {
	margin-top: $top;
	margin-bottom: $bottom;
}

@mixin margin-LR($left,$right) {
	margin-left: $left;
	margin-right: $right;
}

@mixin background-size($size) {
	-webkit-background-size: $size;
	-moz-background-size: $size;
	background-size: $size;
}

//== Breakpoint
@mixin media($id) {
	@each $media in $media-stack {
		@if ($id == map-get($media, id)) {
			$rule: map-get($media, rule);
			@media #{$rule} {
				@content
			}
		}
	}
}

//== Form
@mixin placeholder {
	&::-webkit-input-placeholder {
		@content
	}
	&:-moz-placeholder {
		@content
	}
	&::-moz-placeholder {
		@content
	}
	&:-ms-input-placeholder {
		@content
	}
}

@mixin placeholder-style($color, $style:normal, $isFocusHidden:false) {

	@include placeholder {
		color: $color;
		text-overflow: ellipsis;
		font-style: $style;
		opacity: 1;
	}

	@if ($isFocusHidden == true) {
		@include placeholder {

			transition: $transition-fade;
			&:focus {
				color: transparent;
				opacity: 0;
			}
		}
	}
}

//== Animations
@mixin transition($transition...) {
	@if length($transition) == 0 {
		transition: $transition-base;
	} @else {
		transition: $transition;
	}
}

@mixin start-animate($name, $duration: $base-duration, $delay: 0s) {
	//@extend .#{$name};
	animation-name: $name;
	animation-delay: $delay;
	animation-duration: $duration;
	animation-fill-mode: $duration;
}

@mixin link-border-hover($isSpan: false, $borderStyle: solid) {

	@if ($isSpan == true) {
		span {
			border-bottom: 1px $borderStyle transparent;
			transition: transition-base(border-bottom-color);
		}
		&:hover {
			span {
				border-bottom-color: currentColor;
			}
		}
	} @else {
		border-bottom: 1px $borderStyle transparent;
		&:hover {
			border-bottom-color: currentColor;
		}
	}

}

%text {
	font-size: $font-size-base;
	@include media(mobile) {
		font-size: $font-size-xs-base;
	}
}
%text-small {
	font-size: $text-small-font-size;
	@include media(mobile) {
		font-size: $text-small-xs-font-size;
	}
}

%h1 {
	font-size: $font-size-h1;
	@include media(mobile) {
		font-size: $font-size-xs-h1;
	}
}

%h2 {
	font-size: $font-size-h2;
	@include media(mobile) {
		font-size: $font-size-xs-h2;
	}
}

%h3 {
	font-size: $font-size-h3;
	@include media(mobile) {
		font-size: $font-size-xs-h3;
	}
}

%h1,%h2 {
	line-height: $headings-line-height;
}
%h3,%h4,%h5,%h6 {
	line-height: $line-height-base;
}

%h4 {
	font-size: $font-size-h4;
	@include media(mobile) {
		font-size: $font-size-xs-h4;
	}
}

%h5 {
	font-size: $font-size-h5;
	@include media(mobile) {
		font-size: $font-size-xs-h5;
	}
}

%h6 {
	font-size: $font-size-h6;
	@include media(mobile) {
		font-size: $font-size-xs-h6;
	}
}

%h {
  color: $headings-color;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}







