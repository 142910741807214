
ul.prev-next-links {
	@include ul-reset;
}

ul.prev-next-links,
.action-links {
	margin-top: 60px;
	display: flex;
	@include padding-column-reset();
	.prev-next-link-prev,
	.prev-next-link-next,
	.btn-prev,
	.btn-next {
		flex-basis: 50%;
		max-width: 50%;
		@include padding-column();
		a {
			@extend %btn;

			position: relative;
			&:before,
			&:after {
				transition: left $btn-fa-transition;
				position: relative;
				left: 0;
				vertical-align: middle;
			}
		}
		&.disabled {
			a {
				@extend %btn-disabled-fill;

			}
			&:hover {
				a {
					&:before,
					&:after {
						left: 0;
					}
				}
			}
		}
	}
	.prev-next-link-prev,
	.btn-prev {
		text-align: left;
		a {
			@include btn-style(fill, gray);
			&:before {
				@include fa-icon;
				content: $fa-var-long-arrow-left;
				margin-right: $btn-fa-indent;
			}
			&:hover {
				&:before {
					left: -$btn-fa-indent-animation;
				}
			}
		}
	}
	.prev-next-link-next,
	.btn-next {
		text-align: right;
		a {
			@include btn-style();
			&:after {
				@include fa-icon;
				content: $fa-var-long-arrow-right;
				margin-left: $btn-fa-indent;
			}
			&:hover {
				&:after {
					left: $btn-fa-indent-animation;
				}
			}
		}
	}

	@include media(mobile) {
		margin-top: 35px;
		justify-content: center;
		.prev-next-link-prev,
		.prev-next-link-next,
		.btn-prev,
		.btn-next {
			flex-basis: auto;
			max-width: none;

			a {
				font-size: 0;
				color: transparent;
				//padding: 0 5px;
				padding: 0;
				min-width: 40px;
				height: 40px;
				line-height: 40px;
				border: 0 none;
				&:before,
				&:after {
					color: $c-white;
					font-size: 15px;
					@include margin-LR(0, 0);
				}
				&:hover {
					&:before,
					&:after {
						left: 0;
					}
				}
			}
		}
	}
}

.pager-load-more {
	&.pager-load-more-empty {
	display: none;}
	&,
	.item-list & {

		//reset
		@include ul-reset;
		li {
			margin: 0;
			display: inline-block;
			vertical-align: middle;
			padding: 0;
		}

		margin-top: 45px;
		@include media(mobile) {
						margin-top: 20px;
		}
	}

	li {
		position: relative;
	}

	a {
		@extend .btn-load-more;
	}
	//huck for ajax-progress
	.ajax-progress {
		position: absolute;
		left: 30px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 2;
	}
}

