.region-content-top-filter {
	@include make-row;

	@include h-all {
		margin: 0;
	}

	@include media(tablet-desktop) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: 10px;

		.block {
			&:first-child,
			&:first-child:last-child {
				flex: 1;
				flex-basis: 40%;
				max-width: 70%;
			}

			&:last-child {
				flex-basis: 33%;
				//width: 33%;

			}
		}
	}

	@include media(mobile) {
		.block {
			text-align: center;

			+ .block {
				margin-top: 15px;
			}
		}
	}

	.block {
		@include padding-column();
		margin-bottom: 30px;
		@include media(mobile) {
			margin-bottom: 15px;
		}

		//&.block-filter-multiple {
		flex-basis: auto;
		width: auto;

		.views-exposed-widgets {
			display: flex;
			@include make-row;
			justify-content: flex-end;
			position: relative;
		}

		.views-exposed-widget {
			flex: 1;
			width: 290px;

			&.views-submit-button,
			&.views-reset-button {
				width: auto;
			}

			.views-widget {
				//max-width: 290px;
				//@include media(mobile) {
				//	max-width: 100%;
				//}
			}
		}

		.views-reset-button {
			flex: initial;
		}

		.views-widget {
			@include padding-column();
		}

		@include media(mobile) {
			width: 100%;

			.views-exposed-widgets {
				display: block;
				padding-right: 55px;
			}
			.views-exposed-widget {
				width: 100%;
			}

			.views-widget {
				@include margin-TB(8px, 8px);
			}

			.btn.dropdown-toggle {
				width: 100%;
			}

			.views-reset-button {
				//display: none;

				position: absolute;
				top: 0;
				right: 0;
				margin-top: 10px;

				.form-submit,
				input[type="submit"],
				input[type="reset"] {
					margin: 0;
					display: block;
					background: no-repeat center;
					//icon reset
					background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4yLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHdpZHRoPSIyNXB4IiBoZWlnaHQ9IjI1cHgiIHZpZXdCb3g9IjAgMCAyNSAyNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjUgMjU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIHN0eWxlPSJmaWxsOiM4RjhGOEY7IiBkPSJNMjEuODUsMjAuMjU2Yy0wLjE3NCwwLTAuMzQ5LTAuMDYxLTAuNDkxLTAuMTgzYy0wLjMxMy0wLjI3MS0wLjM0Ny0wLjc0NS0wLjA3Ni0xLjA1OQ0KCQljMC4zNDEtMC4zOTMsMi4wMzgtMi41MDQsMi4wMzgtNS40NzZjMC00LjYxOC0zLjc1Ny04LjM3NS04LjM3NS04LjM3NVM2LjU3LDguOTIxLDYuNTcsMTMuNTM5djUuNzgxYzAsMC40MTQtMC4zMzYsMC43NS0wLjc1LDAuNzUNCgkJcy0wLjc1LTAuMzM2LTAuNzUtMC43NXYtNS43ODFjMC01LjQ0NSw0LjQzLTkuODc1LDkuODc1LTkuODc1czkuODc1LDQuNDMsOS44NzUsOS44NzVjMCwzLjUwMy0yLjAwMiw1Ljk5NC0yLjQwMyw2LjQ1OA0KCQlDMjIuMjY5LDIwLjE2OCwyMi4wNiwyMC4yNTYsMjEuODUsMjAuMjU2eiIvPg0KCTxwYXRoIHN0eWxlPSJmaWxsOiM4RjhGOEY7IiBkPSJNNS45MywyMS4zMzZjLTAuMTkyLDAtMC4zODQtMC4wNzMtMC41My0wLjIyYy0wLjI5My0wLjI5My0wLjI5My0wLjc2OCwwLTEuMDYxbDQuNzgxLTQuNzgxDQoJCWMwLjI5My0wLjI5MywwLjc2OC0wLjI5MywxLjA2MSwwczAuMjkzLDAuNzY4LDAsMS4wNjFMNi40NiwyMS4xMTZDNi4zMTMsMjEuMjYzLDYuMTIyLDIxLjMzNiw1LjkzLDIxLjMzNnoiLz4NCgk8cGF0aCBzdHlsZT0iZmlsbDojOEY4RjhGOyIgZD0iTTUuNzExLDIxLjMzNmMtMC4xOTIsMC0wLjM4NC0wLjA3My0wLjUzLTAuMjJsLTQuNzgxLTQuNzgxYy0wLjI5My0wLjI5My0wLjI5My0wLjc2OCwwLTEuMDYxDQoJCXMwLjc2OC0wLjI5MywxLjA2MSwwbDQuNzgxLDQuNzgxYzAuMjkzLDAuMjkzLDAuMjkzLDAuNzY4LDAsMS4wNjFDNi4wOTUsMjEuMjYzLDUuOTAzLDIxLjMzNiw1LjcxMSwyMS4zMzZ6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==");
					width: 30px;
					height: 30px;
				}

				//	@extend %btn;
				//	@extend %btn-border;
			}
		}
		//}
	}

	.ajax-progress {
		display: none;
	}

	//filter
	& {

	}

	.views-exposed-widgets {
		margin: 0;
		position: relative;
	}

	.views-exposed-widget {
		padding: 0;
		float: none;
	}

	div[class^="views-widget-filter"] {

	}

	.form-submit {
		@include btn-reset;
		//text-indent: -9999px;
		font-size: 0;
		color: transparent;
		margin: 0;
		display: inline-block;
		vertical-align: middle;
		background: no-repeat center;

		width: 25px;
		height: 25px;
		box-sizing: content-box;
		@include padding-LR(15px, 15px);
	}

	.ctools-auto-submit-processed {
		.views-submit-button {
			display: none;
		}
	}

	.views-reset-button {
		//display: none;
		.form-submit {
			cursor: pointer;
			margin: 0;
			height: 100%;
			//background-image: url(/sites/all/themes/911/images/icon-reset.svg);
			background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4yLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHdpZHRoPSIyNXB4IiBoZWlnaHQ9IjI1cHgiIHZpZXdCb3g9IjAgMCAyNSAyNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjUgMjU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIHN0eWxlPSJmaWxsOiM4RjhGOEY7IiBkPSJNMjEuODUsMjAuMjU2Yy0wLjE3NCwwLTAuMzQ5LTAuMDYxLTAuNDkxLTAuMTgzYy0wLjMxMy0wLjI3MS0wLjM0Ny0wLjc0NS0wLjA3Ni0xLjA1OQ0KCQljMC4zNDEtMC4zOTMsMi4wMzgtMi41MDQsMi4wMzgtNS40NzZjMC00LjYxOC0zLjc1Ny04LjM3NS04LjM3NS04LjM3NVM2LjU3LDguOTIxLDYuNTcsMTMuNTM5djUuNzgxYzAsMC40MTQtMC4zMzYsMC43NS0wLjc1LDAuNzUNCgkJcy0wLjc1LTAuMzM2LTAuNzUtMC43NXYtNS43ODFjMC01LjQ0NSw0LjQzLTkuODc1LDkuODc1LTkuODc1czkuODc1LDQuNDMsOS44NzUsOS44NzVjMCwzLjUwMy0yLjAwMiw1Ljk5NC0yLjQwMyw2LjQ1OA0KCQlDMjIuMjY5LDIwLjE2OCwyMi4wNiwyMC4yNTYsMjEuODUsMjAuMjU2eiIvPg0KCTxwYXRoIHN0eWxlPSJmaWxsOiM4RjhGOEY7IiBkPSJNNS45MywyMS4zMzZjLTAuMTkyLDAtMC4zODQtMC4wNzMtMC41My0wLjIyYy0wLjI5My0wLjI5My0wLjI5My0wLjc2OCwwLTEuMDYxbDQuNzgxLTQuNzgxDQoJCWMwLjI5My0wLjI5MywwLjc2OC0wLjI5MywxLjA2MSwwczAuMjkzLDAuNzY4LDAsMS4wNjFMNi40NiwyMS4xMTZDNi4zMTMsMjEuMjYzLDYuMTIyLDIxLjMzNiw1LjkzLDIxLjMzNnoiLz4NCgk8cGF0aCBzdHlsZT0iZmlsbDojOEY4RjhGOyIgZD0iTTUuNzExLDIxLjMzNmMtMC4xOTIsMC0wLjM4NC0wLjA3My0wLjUzLTAuMjJsLTQuNzgxLTQuNzgxYy0wLjI5My0wLjI5My0wLjI5My0wLjc2OCwwLTEuMDYxDQoJCXMwLjc2OC0wLjI5MywxLjA2MSwwbDQuNzgxLDQuNzgxYzAuMjkzLDAuMjkzLDAuMjkzLDAuNzY4LDAsMS4wNjFDNi4wOTUsMjEuMjYzLDUuOTAzLDIxLjMzNiw1LjcxMSwyMS4zMzZ6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==");
			opacity: .5;
			transition: transition-base(opacity);

			&:hover, &:focus {
				opacity: 1;
			}

			&.disabled {
				cursor: default;
				&, &:hover, &:focus {
					opacity: .3 !important;
				}
			}
		}
	}
}
