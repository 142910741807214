$font-path: '../fonts/';

@mixin font-face($font-field, $font-family, $font-variant, $font-weight, $font-style) {
	$font-full-path: '#{$font-path}#{$font-field}/#{$font-family}';

	@font-face {
		font-family: $font-family;
		src: url('#{$font-full-path}-#{$font-variant}.eot');
		src: local('#{$font-family} #{$font-variant}'),
		local('#{$font-family}-#{$font-variant}'),
		url('#{$font-full-path}-#{$font-variant}.woff') format('woff'),
		url('#{$font-full-path}-#{$font-variant}.ttf') format('truetype'),
		url('#{$font-full-path}-#{$font-variant}.svg##{$font-family}') format('svg');
		font-weight: $font-weight;
		font-style: $font-style;
	}

}

@include font-face('roboto','Roboto', 'Thin', 100, normal);
@include font-face('roboto', 'Roboto', 'Light', 300, normal);
@include font-face('roboto', 'Roboto', 'Regular', 400, normal);
@include font-face('roboto', 'Roboto', 'Medium', 500, normal);
@include font-face('roboto','Roboto', 'Bold', 700, normal);

