.progress-bar {
  .progress-bar-layer {
    width: 10%;
    height: 10px;
    background-color: color-themify('base');
    @include media(mobile) {
      height: 4px;
    }
  }
}









