.section-links {
	@include media(tablet-mobile) {
		padding-bottom: 0;
	}

	.card-group {
		margin-top: 40px;
		&:first-child {
			margin-top: 0;
		}
		@include media(tablet-mobile) {
			margin-top: 0;
		}
	}
}

.card-group {
	display: flex;
	@include padding-column-reset();

	@include media(tablet){
		@include margin-LR(-$container-sm-padding-horizontal,-$container-sm-padding-horizontal);
	}

	.card {
		display: flex;
		flex-direction: column;
		flex: 1 0 0%;
		@include padding-column();
	}

	.card-body {
		flex: 1 1 auto;
	}
	.card-footer {
		margin-top: 15px;
	}

	.title {
		margin-top: 0;
	}
	.title,
	.link-more {
		position: relative;
		z-index: 2;
	}
	.link-more {

	}

	//[todo]
	.card-link-overlay {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	@include media(tablet-mobile) {
		flex-direction: column;
		.card {
			position: relative;
			@include padding-TB(55px, 40px);
			&:first-child {
				padding-top: 20px;
			}
			text-align: center;
			+ .card {
				border-top: 1px solid rgba(#c3c5c5, .3);
			}
		}

		.card-link-overlay {
			display: block;
		}
	}

	@include media(mobile) {
		.card {
			@include padding-TB(25px, 30px);
		}
	}
}