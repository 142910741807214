form {
	margin: 0;
}

.views-exposed-widget {

}

//select {
//	visibility: hidden;
//}
.form-type-select {
	.form-select {
	}
}

select {
	font-size: $select-font-size;
	padding: $select-padding-vertical $select-padding-horizontal;
	border: 1px solid $select-border-color;
	color: $select-color;
	&:focus {
		outline: none;
		border-color: darken($select-border-color, 15%);
	}
}

html.js select {
	visibility: hidden;
	opacity: 0;
}

.bootstrap-select {
	&:focus {
		outline: 0 none;
	}
	select {
		display: none !important;
	}

	&:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
		width: 100%;
	}

	.btn.dropdown-toggle {
		position: relative;
		font-size: $select-font-size;
		font-weight: $select-font-weight;
		border-radius: $select-border-radius;
		border-color: $select-border-color;
		color: $select-color;
		padding: $select-padding-vertical $select-padding-horizontal;
		height: $input-height-base;
		padding-right: $select-caret-width;
		border-width: 1px;

		.filter-option {
			display: block;
			max-width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&:focus {
			outline: 0 none !important;
		}

		.bs-caret {
			width: $select-caret-width;
			height: 100%;
			position: absolute;
			right: 0;
			top: 0;
			text-align: center;
		}

		.caret {
			display: inline-block;
			vertical-align: middle;
			border: solid $select-caret-color;
			border-width: 0 2px 2px 0;
			padding: 3px;
			transform: rotate(45deg) translateY(-50%);
			position: absolute;
			top: 50%;
			right: $select-padding-horizontal;
			//transition: transition-base(transform);
		}

		&:hover {
			.caret {
				color: $select-caret-active-color;
			}
		}

		@include media(mobile) {
			@include padding-TB($select-xs-padding-vertical, $select-xs-padding-vertical);
			padding-left: $select-xs-padding-horizontal;
			height: $input-xs-height-base;

		}
	}

	> .dropdown-menu {
		max-height: 400px;
	}

	.dropdown-menu {
		@include margin-TB(0, 0);
		@include padding-TB(0, 0);
		font-size: $select-font-size;
		background-color: $form-dropdown-bg;
		border-color: $form-dropdown-border;
		border-radius: $select-border-radius;

		.divider {
			@include nav-divider($form-dropdown-divider-bg);
		}

		> li {
			border-top: 1px solid $form-dropdown-border;
			&:first-child {
				border-top: 0 none;
			}
		}

		> li > a {
			padding: $form-dropdown-link-padding;
			font-weight: $select-font-weight;
			line-height: 1.26;
			color: $form-dropdown-link-color;
			white-space: normal;
			&:hover,
			&:focus {
				color: $form-dropdown-link-hover-color;
				background-color: $form-dropdown-link-hover-bg;
			}
			@include media(mobile) {
				padding: $form-dropdown-xs-link-padding;
			}
		}

		> .selected > a {
			&,
			&:hover,
			&:focus {
				color: $form-dropdown-link-active-color;
				background-color: $form-dropdown-link-active-bg;
			}
		}

		> .disabled > a {
			&,
			&:hover,
			&:focus {
				color: $form-dropdown-link-disabled-color;
			}
		}
	}

	&:not(.dropup) {
		.dropdown-menu {
			border-top: 0 none;
			box-shadow: $form-dropdown-box-shadow;
		}
	}
	&.dropup {
		.dropdown-menu {
			border-bottom: 0 none;
			box-shadow: $form-dropdown-dropup-box-shadow;
		}
	}

	&.open {
		.btn.dropdown-toggle {
			.caret {
				transform: rotate(225deg);
			}
		}
	}

}

.form-required {
	color: $form-error-color;
}

.form-control {
	&, &:focus {
		@include box-shadow(none);
	}

	border-width: 2px;
	padding: $input-padding-vertical $input-padding-horizontal;

	@include media(mobile) {
		border-width: 1px;
		height: $input-xs-height-base;
		font-size: $input-xs-font-size;
		@include padding-LR($input-xs-padding-horizontal, $input-xs-padding-horizontal);
	}
}

.form-type-textfield {
	position: relative;
}

.form-text {
	@extend .form-control;
}

#autocomplete {
	@include start-animate(fadIn, .3s);
	//default styles in system.base.css
	border-color: $form-dropdown-border;
	border-top: 0 none;
	background-color: $form-dropdown-bg;
	box-shadow: $form-dropdown-box-shadow;
	margin: 0;
	//margin-right: -30px;

	.form-type-textfield & {
		left: 0 !important;
		width: 100% !important;
		top: 100%;
		overflow: hidden;
	}

	ul {
		display: block;
		margin: 0;
		padding: 0;
		max-height: 400px;
		overflow: hidden;
		overflow-y: auto;
	}

	li {
		cursor: pointer;
		width: 100%;
		font-size: $input-font-size;
		font-weight: $input-font-weight;
		color: $form-dropdown-link-color;
		background: transparent;
		padding: 0 $input-padding-horizontal;
		height: $input-height-base;
		line-height: $input-height-base;
		transition: transition-base(color),transition-base(background-color);

		border-bottom: 1px solid $form-dropdown-divider-bg !important;
		&:last-child {
			border-bottom: 0 none;
		}

		span {
			display: inline-block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&:focus,
		&:active,
		&.active,
		&.selected,
		&:hover {
			color: $form-dropdown-link-hover-color;
			background-color: $form-dropdown-link-hover-bg !important;
		}
	}

	@include media(mobile) {
		li {
			font-size: $input-xs-font-size;
			@include padding-LR($input-xs-padding-horizontal, $input-xs-padding-horizontal);
			height: $input-xs-height-base;
			line-height: $input-xs-height-base;
		}
	}
}

//}

.form-search {
	.ajax-progress {
		display: none;
	}
	.form-text {
		padding-right: $input-padding-horizontal*2;
	}
	.views-exposed-widgets {
		position: relative;
		margin-bottom: 0; //.5em;

		input.form-autocomplete {
			//background-image: url(../../misc/throbber-inactive.png);
			//background-position: 100% center;
			//background-repeat: no-repeat;
			background-image: none;
		}

	}

	.views-exposed-widget {
		float: none; //left;
		padding: 0; //reset = .5em 1em 0 0;
	}

	.views-submit-button {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: $input-padding-horizontal*2;
		text-align: center;
		color: rgba(#383a3d, .8);

		.form-submit {
			@extend %btn-clear;
			font-size: 0;
			color: transparent;
			text-indent: -9999px;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			padding: 0;
			margin: 0;
			opacity: 0;
			z-index: 2;
		}

		&:before {
			content: $fa-var-search;
			@include fa-icon;
			font-size: 21px;
			display: inline-block;
			position: absolute;
			z-index: 1;
			top: 50%;
			right: $input-padding-horizontal;
			transform: translateY(-50%);
			@include media(mobile) {
				font-size: 14px;
				right: $input-xs-padding-horizontal;

			}
		}
	}
}

.error-message {
	font-size: 16px;
	color: rgb(185, 47, 50);
	@extend %text-small;
	@include margin-TB(20px, 20px);
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
}












