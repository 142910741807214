.icon {
	display: inline-block;
	vertical-align: middle;
	background-repeat: no-repeat;
	background-position:  0 0;
}

.icon-sprite,
.svg-icon {
	fill: currentColor;
}






