.field-type-image {
    @include margin-TB(25px, 35px);
    @include media(mobile) {
        @include margin-TB(10px, 15px);
    }
    &.field-name-field-big-picture {
        &:first-child {
            margin-top: 0;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
}

.row-content-list {
    margin-top: 45px;
    &:first-child {
        margin-top: 0;
    }
    .title {
        margin-top: 0;
    }
    .block {
        @include padding-TB(60px, 60px);
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
        }
        + .block {
            position: relative;
            &:before {
                content: "";
                display: block !important;
                height: 1px;
                background-color: #ededed;
                position: absolute;
                top: 0;
                left: $grid-gutter-width/2;
                right: $grid-gutter-width/2;
                @include media(tablet) {
                    left: -($container-sm-padding-horizontal - $grid-gutter-width/2);
                    right: -($container-sm-padding-horizontal - $grid-gutter-width/2);
                }
                @include media(mobile) {
                    left: 0;
                    right: 0;
                }
            }
        }
        &:nth-child(odd) {
            .row {
                > div:first-child, {
                    @include media(desktop) {
                        order: 2;
                    }
                }
            }
        }
        @include media(desktop) {
            .row {
                @include row-flex;
            }
        }
        @include media(tablet) {
            padding-top: 80px;

        }
        @include media(mobile) {
            @include padding-TB(35px, 35px);
            &:first-child {
                padding-top: 20px;
            }
        }
    }

    @include media(tablet-mobile) {
        text-align: center;
        .img {
            margin: 20px auto 30px;
            &:first-child {
                margin-top: 0;
            }
        }
    }
    @include media(tablet) {
        .img {
            max-width: 70%;
        }
    }
    @include media(mobile) {
        margin-top: 0;
        margin-bottom: 20px;
        .img {
            max-width: 90%;
        }
    }
}

.section-content {
    .field-type-image {
        img {
            width: 100%;
        }
    }

    .field-name-body {
        img {
            display: inline-block;
            margin-top: 10px;
            margin-bottom: 30px;
            margin-left: auto;
            margin-right: auto;
        }
        img.alignleft,
        img[style^="float:left"] {
            margin-right: 30px;
        }
        img.alignright,
        img[style^="float:right"] {
            margin-left: 30px;
        }

        @include media(mobile) {
            img {
                margin-bottom: 15px;
            }
            img.alignleft,
            img[style^="float:left"],
            img.alignright,
            img[style^="float:right"] {
                float: none;
                display: block;
                @include margin-LR(0, 0);
            }
        }
    }
}

.margin-top-30 {
    margin-top: 30px;
}

.full-width {
    width: 100%;
}

.row-title-center {
    h1,h2,h3,h4,h5,h6 {
        text-align: center;
    }
}



