$mCSB-dragger_bar-color: #dadada;
$mCSB-dragger_bar-hover-color: darken($mCSB-dragger_bar-color, 20%);
$mCSB-dragger_bar-active-color: darken($mCSB-dragger_bar-color, 20%);

$mCSB-draggerRail-color: transparent;


.mCSB_scrollTools .mCSB_draggerRail {
	background-color: $mCSB-draggerRail-color;
}

.mCSB_scrollTools .mCSB_dragger {
	.mCSB_dragger_bar {
		background-color: $mCSB-dragger_bar-color;
	}
	&:hover {
		.mCSB_dragger_bar {
			background-color: $mCSB-dragger_bar-hover-color;
		}
	}
}

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{
  background-color: $mCSB-dragger_bar-active-color;
}





.mCS-white {
	//.mCSB_scrollTools .mCSB_draggerRail {
	//	background-color: #000000;
	//	background-color: rgba(0, 0, 0, 0.15);
	//}
	//
	//.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
	//	background-color: #000000;
	//	background-color: rgba(0, 0, 0, 0.75);
	//}
	//
	//.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
	//	background-color: rgba(0, 0, 0, 0.85);
	//}
	//
	//.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	//.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
	//	background-color: rgba(0, 0, 0, 0.9);
	//}
}

.mCS-gray {
	//.mCSB_scrollTools .mCSB_draggerRail {
	//	background-color: #000000;
	//	background-color: rgba(0, 0, 0, 0.15);
	//}
	//
	//.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
	//	background-color: #000000;
	//	background-color: rgba(0, 0, 0, 0.75);
	//}
	//
	//.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
	//	background-color: rgba(0, 0, 0, 0.85);
	//}
	//
	//.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	//.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
	//	background-color: rgba(0, 0, 0, 0.9);
	//}
}
