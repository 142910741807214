.chartdiv {
	display: none;
	opacity: 0;
	transition: transition-base(opacity);
}

@mixin chartdiv($width, $f-percent, $f-state) {
	width: $width;
	.chart-percent {
		font-size: $f-percent;
	}
	.chart-state {
		font-size: $f-state;
	}
}

.chartdiv-wrap {
	width: 360px;
	max-width: 100%;
	@include margin-LR(auto, auto);

	box-sizing: content-box;

	.chartdiv-inner {
		position: relative;
		width: 100%;
		padding-bottom: 100%;
		overflow: hidden;
		border-radius: 50%;
		background: #eeeeee;
		background: -moz-radial-gradient(center, ellipse cover, #ffffff 0%, #ffffff 18%, #eeeeee 100%); /* ff3.6+ */
		background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, #ffffff), color-stop(18%, #ffffff), color-stop(100%, #eeeeee)); /* safari4+,chrome */
		background: -webkit-radial-gradient(center, ellipse cover, #ffffff 0%, #ffffff 18%, #eeeeee 100%); /* safari5.1+,chrome10+ */
		background: -o-radial-gradient(center, ellipse cover, #ffffff 0%, #ffffff 18%, #eeeeee 100%); /* opera 11.10+ */
		background: -ms-radial-gradient(center, ellipse cover, #ffffff 0%, #ffffff 18%, #eeeeee 100%); /* ie10+ */
		background: radial-gradient(ellipse at center, #ffffff 0%, #ffffff 18%, #eeeeee 100%); /* w3c */
	}

	.chartdiv {
		position: absolute;
		z-index: 2;
		display: block;
		opacity: 1;
		width: 80%;
		height: 80%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		canvas:first-child {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);

			//huck for mobile, because plugin added width, height in style
			width: 100% !important;
			height: 100% !important;
		}

		canvas {
			width: 100%;
			height: auto;
		}
	}

	.chart-canvas-default {

	}

	.chart-info {
		font-weight: $font-weight-regular;
		text-align: center;
		position: absolute;
		width: 70%;
		left: 50%;
		top: 35%;
		transform: translateX(-50%);
	}

	.chart-percent {
		font-size: 72px;
		line-height: 1;
		color: $c-black;
		display: block;
		white-space: nowrap;
		margin-bottom: 13%;
	}

	.chart-state {
		color: #888f90;
		//font-size: 24px;
		@extend %h4;
		line-height: 1;
		display: block;
		opacity: 0;
		transition: transition-base(opacity);
	}

	.in {
		.chart-state {
			opacity: 1;
		}
	}
	@include media(tablet-mobile) {
		.chartdiv {
			width: 86%;
			height: 86%;
		}
	}

	@include media(mobile) {
		width: 180px;

		.chart-percent {
			font-size: 36px;
			margin-bottom: 10%;
		}

	}
}

.small-text {
	display: block;
	color: #888f90;
	font-size: 16px;

	@include media(mobile) {
		font-size: $font-size-xs-base;
	}
}

.title-final {
	@extend .color-base;
	font-weight: $font-weight-medium;
}

.risk-compass-wrap {
	//@include make-grid();
	//@include padding-column-reset();
	text-align: center;

	.risk-compass-diagram {
		margin-bottom: 45px;
	}

	.content {
		text-align: left;
	}

	@include media(desktop) {
		display: flex;
		align-items: center;
		text-align: left;

		> div,
		.risk-compass-diagram,
		.risk-compass-questions {
			@include padding-column();
		}
		.risk-compass-diagram {
			order: 2;
			padding-left: 50px;
		}
		.risk-compass-questions {
			order: 1;
		}
	}

	@include media(mobile) {
		.risk-compass-diagram {
			margin-top: 30px;
			margin-bottom: 25px;
		}
	}
}

.page-risk-compass-start {
	@include media(desktop) {
		.page-title {
			margin-bottom: 0;
		}
	}
	@include media(tablet) {
		.page-content {
			.container {
				@include padding-LR(45px, 45px);
			}
		}
	}
}

/* Modal risk-compass
--------------------------*/
$modal-padding-horizontal: 50px;
$modal-padding-horizontal-sm: 60px;
$modal-padding-horizontal-xs: 15px;

.modal-risk-compass {

	.modal-body {
		padding: 50px 35px 40px;
	}

	.modal-header,
	.modal-body {
		padding-left: $modal-padding-horizontal;
	}
	.form-radios {
		margin-right: -30px;
	}
	.mCSB_container {

		&.mCS_no_scrollbar_y {
			margin-right: 30px;
		}
	}

	@include media(desktop) {
		.modal-body {
			padding: 0;
			display: flex;
		}
		.risk-compass-questions,
		.risk-compass-result {
			padding: 50px;
			padding-top: 40px;
			width: 50%;
			margin: 0;
		}

		.risk-compass-questions {
			order: 1;
			//padding-right: 30px;
			position: relative;
		}

		.risk-compass-result {
			background-color: #f3f3f2;
			order: 2;
		}

		.risk-compass-questions-list {
			min-height: 600px;

		}
	}

	@include media(tablet) {
		.modal-header {
			padding-left: $modal-padding-horizontal-sm;
		}
		.modal-body {
			@include padding-LR($modal-padding-horizontal-sm, $modal-padding-horizontal-sm);
		}
		.risk-compass-result {
			margin-bottom: 30px;
		}
	}

	@include media(mobile) {
		.modal-header {
			padding-left: $modal-padding-horizontal-xs;
		}
		.modal-body {
			padding: 25px $modal-padding-horizontal-xs;
			padding-right: 40px;
		}

		.risk-compass-result {
			margin-bottom: 15px;
		}
	}
}

.modal-risk-compass-tip {
	background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
	.modal-body {
		padding-top: 0;
		text-align: center;
	}

	.title {
		@extend %h;
		@extend %h2;
		font-weight: $font-weight-medium;
		margin-bottom: 25px;
		.fa {
			color: rgba(#c3c5c5, .5);
			margin-right: 15px;
			transform: scale(1.2);
			transform-origin: bottom right;
		}
	}

	.tip-content {
		text-align: left;
	}

	.modal-header {
		padding: 0;
		.modal-close {
			padding-bottom: 0;
		}
	}
	.modal-footer {
		padding-top: 10px;
	}
	@include media(mobile) {
		.title {
			margin-bottom: 10px;
		}

		.modal-footer {
			padding-top: 0;
		}
	}
}

.risk-compass-result {

	.risk-compass-tip {
		@include media(tablet-mobile) {
			display: none;
		}
		font-size: 16px;
		opacity: 0;
		transition: transition-base(opacity);

		&.in {
			opacity: 1;
		}
		.small-text {
			color: #888f90;
		}

		.title {
			@extend %h5;
			font-weight: $font-weight-medium;
			color: $c-black;

			&:before {
				content: $fa-var-lightbulb-o;
				@include fa-icon;
				margin-right: 10px;
				color: #c3c5c5;

				font-size: 26px;
			}
		}

		.tip-content {
			max-height: 400px;
			overflow: hidden;
			overflow-y: auto;
		}
	}

	.modal:not(.modal-risk-compass-finish) & {
		.chartdiv-wrap {
			@include chartdiv(230px, 48px, 16px);

			@include media(mobile) {
				@include chartdiv(130px, 27px, 12px);
			}
		}
	}

	.modal-risk-compass-finish & {
		@include media(desktop) {

			display: flex;
			flex-direction: column;
			.risk-compass-diagram {
				flex: 1;
			}
		}

		.chartdiv-wrap {
			width: 390px;
			@include media(tablet) {
				@include chartdiv(230px, 48px, 16px);
			}
			@include media(mobile) {
				@include chartdiv(130px, 27px, 12px);
			}
		}
	}
}


.risk-compass-questions {

	.progress-bar {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}

	.title-final {
		@extend .color-base;
	}

	.form-item, .form-actions {
		@include margin-TB(0, 0);
	}

	.risk-compass-questions-list {
		position: relative;

		/*.fieldset-wrapper {
		  position: relative;

		  &:after {
			content: "";
			height: 50px;
			position: absolute;
			left: 0;
			right: 30px;

			bottom: 0;

			background: transparent;
			background: -moz-linear-gradient(top, rgba(255, 255, 255, .5) 32%, rgba(255, 255, 255, 0) 100%);
			background: -webkit-linear-gradient(top, rgba(255, 255, 255, .5) 32%, rgba(255, 255, 255, 0) 100%);
			background: linear-gradient(to bottom, rgba(255, 255, 255, .5) 32%, rgba(255, 255, 255, 0) 100%);
		  }

		}*/

		.mCustomScrollBox {
			@include media(tablet-mobile) {
				height: auto;
			}
		}

		legend,
		#form-ajax-tips {
			display: none;
		}

		@include media(desktop) {
			padding-bottom: 60px + 40px;

			.form-actions {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
			}
		}
	}

	.form-type-radios > label {
		display: block;
		@extend %h4;
		font-weight: $font-weight-regular;
		color: $c-black;
		margin-bottom: 35px;
		@include media(mobile) {
			margin-bottom: 15px;
		}
	}

	.form-radios {
		max-height: 400px;
		overflow: hidden;
		overflow-y: auto;
		//@include padding-LR(25px, 25px);

		.form-radios-label {
			display: none;
		}

		.form-radio {
			opacity: 0;
			height: 0;
			width: 0;
			position: absolute;
			appearance: none;

			margin: 0 !important;
			padding: 0 !important;
		}

		.form-item {
			position: relative;

			+ .form-item {
				margin-top: 10px;
			}

			.ajax-progress {
				position: absolute;
				right: 15px;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		label {
			display: block;
			@extend %btn;
			@include padding-LR(30px, 30px);
			padding: 12px 30px;
			text-align: left;
			border-color: #f3f3f2;
			color: $text-color;
			font-weight: $font-weight-regular;
			@extend %text;
			text-transform: none;
			white-space: normal;

			&:hover {
				border-color: #cecece;
				color: #989898
			}
		}

		.form-radio:checked + label,
		.form-radio:checked + label:hover {
			border-color: color-themify('base');
			color: color-themify('base');
		}

		@include media(tablet) {
			max-height: 360px;
		}
		@include media(mobile) {
			max-height: 220px;
			.form-item {
				label {
					padding: 5px 15px;
					border-width: 1px;
					font-size: 14px;
				}
			}

		}
	}

	.form-submit {
		@extend %btn;
		@include padding-LR(10px, 10px);

		min-width: 185px;

		&:not(.multistep-form-previous-button) {
			@include btn-style();
		}

		&.multistep-form-previous-button {
			@include btn-style(fill, gray);
			float: left;
		}
		&.multistep-form-next-button {
			@include btn-style();
		}
		@include media(mobile) {
			min-width: 110px;
		}
	}

	.form-print-button {
		@extend %btn;
		@include btn-style();
		position: relative;

		&:before {
			content: $fa-var-print;
			@include fa-icon;
			margin-right: 15px;
			font-size: 26px;
			vertical-align: middle;
		}
	}

	.form-actions {
		.ajax-progress {
			display: none;
		}

		@include clearfix;
		@include margin-TB(0px, 0);

		input:not(.multistep-form-previous-button) {
			float: right;
		}
		.multistep-form-previous-button {
			float: left;
			max-width: 50%;
		}
		.multistep-form-next-button {
			float: right;
			max-width: 50%;
		}
		@include media(tablet) {
			margin-top: 40px;
		}
		@include media(mobile) {
			margin-top: 25px;
		}
	}
}

/* Print risk-compass
--------------------------*/
.page-risk-compass-results {

	@media print and (color) {
		* {
			-webkit-print-color-adjust: exact;
			print-color-adjust: exact;
		}
	}
	@media print {
		a[href^=http]:after {
			content: " <" attr(href) "> ";
		}
		a[href^="#"]:after {
			content: "";
		}
		p, li {
			widows: 4;
			orphans: 3;
		}
	}

	.header {
		border-bottom: 1px solid #dfe8ec;
		.navbar-content {
			padding-right: 0;
		}
		@include media(tablet-mobile) {
			position: relative;
			box-shadow: none;
		}
	}

	.footer {
		display: none;
	}

	.page-content {
		padding-top: 0;
	}

	.title-total {
		@extend %h3;
		// float: left;
		margin-bottom: 5px;
	}

	.title-final {
		@extend %h4;
		font-weight: $font-weight-medium;
		margin: 0 0 5px;
	}

	@mixin layout-inline() {
		.risk-compass-result {
			display: flex;
			align-items: center;

			text-align: left;
			@include padding-column-reset();
			> div {
				@include padding-column();
			}
		}
		.risk-compass-diagram {
			order: 2;
			margin-left: 50px;
		}
	}
	@mixin diagramm-large() {
		.risk-compass-diagram {
			.chartdiv-wrap {
				width: 330px;
				text-align: left;

				.chart-percent {
					font-size: 60px;
				}
				.chart-state {
					font-size: 20px;
				}

				#chartdiv {
					left: 20px;
					right: 20px;
					top: 20px;
					bottom: 20px;
				}
			}
		}
	}

	.risk-compass-diagram {
		margin-left: auto;
		margin-right: auto;
		.chartdiv-wrap {
			width: 180px;

			.chart-percent {
				font-size: 36px;
				margin-bottom: 10%;
			}
		}

	}
	.risk-compass-result {
		text-align: center;
		.risk-compass-diagram {
			margin-bottom: 20px;
		}
		//@media print and (min-width: 100mm) {
		//@media (min-width: 100mm) {
		//	@include layout-inline;
		//}
		////@media print and (min-width: 150mm) {
		//@media (min-width: 150mm) {
		//	@include diagramm-large;
		//}
	}

	@include media(desktop) {
		@include layout-inline;
		@include diagramm-large;
	}

	@media print and (min-width: 120mm) {
		@include layout-inline;
	}
	@media print and (min-width: 200mm) {
		@include diagramm-large;
	}
}

.risk-compass-final-tips {
	padding: 0;
	counter-reset: myCounter;
	li {
		list-style: none;
	}

	> li {
		margin-top: 25px;
		padding-top: 10px;
		padding-left: 70px;
		position: relative;
		&:before {
			counter-increment: myCounter;
			content: counter(myCounter) ".";

			color: #888f90;
			font-size: 46px;

			font-weight: $font-weight-bold;

			position: absolute;
			left: 0;
			top: 0;
			z-index: 2;
		}
	}

	.tip {
		font-size: 16px;

		.title {
			margin-bottom: 5px;
		}
	}

	.title-question {
		@media print {
			page-break-after: avoid;
		}
	}

	@include media(mobile) {
		> li {
			margin-top: 15px;
			padding-top: 5px;
			padding-left: 40px;
			&:before {
				font-size: 25px;
			}
		}
		.title {
			margin-bottom: 0;
		}
	}
}












































