.modal-content {
	border-radius: 0;
	border: 0 none;
	@include box-shadow(none);
}

.modal {
	min-width: 320px;

	//.modal-dialog {
	//	opacity: 0;
	//	transition: opacity .5s .1s;
	//}
	//
	//&.fade .modal-dialog,
	//&.in .modal-dialog {
	//	transform: none;
	//
	//}
	//&.in .modal-dialog {
	//	opacity: 1;
	//}


	@include media(mobile) {
		padding-right: 0 !important;
	}
}

.modal-dialog {
	width: 95%;
	max-width: $container-desktop;
	margin: 20px auto;

	@include media(tablet) {
		width: 80%;
		max-width: 595px;
	}

	@include media(mobile) {
		width: 100%;
		@include margin-TB(0, 0);
	}
}

.modal-middle {
	@include media(tablet-mobile) {
		text-align: center;
		padding: 0 !important;
		&:before {
			content: '';
			display: inline-block;
			height: 100%;
			vertical-align: middle;
			margin-right: -4px;
		}

		.modal-dialog {
			display: inline-block;
			text-align: left;
			vertical-align: middle;
		}
	}
}

.modal-small {
	.modal-dialog {
		width: 95%;
		max-width: 470px;
	}

	.modal-body,
	.modal-footer {
		@include padding-LR(30px, 30px);
	}
	.modal-footer {
		padding-bottom: 50px;
	}
	@include media(mobile) {
		.modal-body,
		.modal-footer {
			@include padding-LR(15px, 15px);
		}
		.modal-footer {
			padding-bottom: 25px;
		}
	}
}

.modal-close.btn-clear {
	padding: 30px;
	padding-bottom: 15px;
	opacity: .7;
	transition: transition-base(color), transition-base(opacity);
	color: #cecece;
	cursor: pointer;

	.svg-icon {
		width: 25px;
		height: auto;
		display: inline-block;
		vertical-align: middle;
	}

	&:hover {
		color: darken(#cecece, 30%);
	}
	@include media(tablet) {
		@include padding-LR(35px, 35px);
	}
	@include media(mobile) {
		padding: 15px;

		.svg-icon {
			width: 15px;
		}
	}
}

.modal-header {
	&-dark {
		background-color: $modal-header-bg;
		color: $modal-header-color;
	}
	&-light {

	}
	text-align: right;
	position: relative;
	padding-right: 100px;
	@include padding-TB(25px, 25px);
	@include media(mobile) {
		@include padding-TB(12px, 12px);
	}

	.modal-title ~ .modal-close {
		position: absolute;
		height: 100%;
		right: 0;
		top: 0;
		padding: 15px;
		@include padding-LR(40px, 40px);
		cursor: pointer;
		z-index: 2;
		opacity: 1;

		&,
		&:hover {
			color: inherit;
		}

		&:hover {
			opacity: .8;
		}

		@include media(tablet) {
			@include padding-LR(35px, 35px);
		}
		@include media(mobile) {
			@include padding-LR(15px, 15px);
		}
	}
}

.modal-title {
	color: $modal-header-color;
	@extend %h2;
	text-align: left;

	//drupal block
	.block {
		margin: 0;
	}

	@include h-all {
		@extend %h2;
		color: $modal-header-color;
		margin: 0;
	}

	@include media(mobile) {
		font-size: 20px;
		@include h-all {
			font-size: 20px;
		}
	}
}

.modal-footer {
	&.text-center {
		text-align: center;
	}

	.btn {
		min-width: 170px;
	}
	@include media(mobile) {
		.btn {
			min-width: 110px;
		}
	}
}








