.logo {
	display: block;
	max-width: 100%;
	@include link-reset;
}

.heading {
	margin-bottom: 10px;
	&:last-child {
		margin-bottom: 0;
	}

	text-align: center;
	color: color(grey, light);

	@include h-all {
		&:first-child {
			@extend %h2;
		}
	}

	.title-section {
		@extend %h2;
	}

	&-bar {
		margin-bottom: 30px;

		.title-section {
			margin: 0;
			color: $c-black;
			.fa {
				font-size: 30px;
			}
		}

		.link-more {
			text-align: right;
			display: inline-block;
			padding: $grid-gutter-width/2;
			margin-right: -$grid-gutter-width/2;
			position: relative;
			top: 5px;
		}

		@include media(tablet-desktop) {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			.title-section {
				max-width: 80%;
			}
			.link-more {
				max-width: 20%;
			}
		}

		@include media(mobile) {
			text-align: center;
			margin-bottom: 0;
			.title-section {
				.fa {
					font-size: 16px;
				}
			}
			.link-more {
				top: 0;
			}
		}
	}
}

.heading-meta {
	margin-bottom: 30px;
	color: #888f90;
	> div {
		display: inline-block;
		padding-right: 10px;

		+ div {
			&:before {
				content: "|";
				color: #dedede;
				padding-right: 10px;
			}
		}
	}

	.meta-date {
		white-space: nowrap;

		&:first-letter {
			text-transform: uppercase;
		}

	}
	.meta-category {
		color: color-themify('link');

		span {
			color: #888f90;
		}

		a {
			@include link-border-hover();
		}

	}

	.meta-expert {

	}

	@include media(mobile) {
		margin-bottom: 15px;
	}
}

.item-title {
	.title {
		margin: 0;
	}
}

.item-img {
	a {
		display: block;
		@include link-reset;
	}
	img {
		width: 100%;
		height: auto;
	}
}

.content-info-block {
	$icon-size: 61px;
	color: #888f90;
	font-size: $font-size-base - 2;
	padding-left: 80px;
	@include margin-TB(40px, 40px);
	&:last-child {
		margin-bottom: 0;
	}
	&:first-child {
		margin-top: 0;
	}

	min-height: $icon-size;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	&:before {
		content: $fa-var-info-circle;
		@include fa-icon;
		padding: 1px;
		font-size: $icon-size;
		color: rgba(#c3c5c5, .5);
		position: absolute;
		left: 0;
		//top: 50%;
		//transform: translateY(-50%);
		top: 0;

	}

	@include media(mobile) {
		@include margin-TB(20px, 30px);
		font-size: $font-size-xs-base - 2;
		$icon-size: 35px;
		padding-left: 0;
		min-height: $icon-size;

		&:before {
			font-size: $icon-size;
			position: static;
			top: auto;
			left: auto;
			display: block;
			text-align: center;
			margin: 0 auto 15px;
		}
	}

}

.content-block-location {
	@include margin-TB(30px, 30px);
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
	.content {
		&:before {
			content: $fa-var-map-marker;
			@include fa-icon;
			color: color-themify(base);
			float: left;
			margin-right: 10px;
			font-size: 25px;

		}
	}
	@include media(mobile) {
		@include margin-TB(20px, 20px);
		.content {
			&:before {
				font-size: 16px;
				margin-right: 5px;
			}
		}
	}
}

.block-logo-list {
	margin-top: 20px;

	img {
		display: inline-block;
		margin-top: 30px;
		@include margin-LR($grid-gutter-width, $grid-gutter-width);
		transition: transition-base(opacity);
		opacity: .5;
	}

	a {
		display: inline-block;
		@include link-reset;
		border-bottom: 0 none;
		&:hover {
			img {
				opacity: .8;
			}
		}
	}
}

.margin-bottom-0 {
	margin-bottom: 0;
}

.block-404,
.block-search-empty {
	text-align: center;
	@include padding-TB(60px, 50px);

	.img {
		margin: 0 auto 50px;
	}

	@include media(tablet-mobile) {
		padding-top: 40px;
		.img {
			width: 70%;
			margin-bottom: 20px;
		}
	}
	@include media(mobile) {
		padding-top: 40px;
	}
}

.blog-expert {
	margin-top: 15px;
}

.block-404 {
	@include media(desktop) {
		img {
			max-height: 469px;
			width: auto;
		}
	}
}


%metadata-style {
	color: #888f90;
	font-size: 16px;
	display: inline-block;

	&.item-date {
		white-space: nowrap;
		span {
			display: inline-block;
			vertical-align: top;
			&:first-letter {
				text-transform: uppercase;
			}
		}
	}
	div {
		display: inline;
	}
	a {
		position: relative;
		z-index: 3;
		@include link-border-hover();
	}
	@include media(mobile) {
		font-size: 12px;
	}
}

