//
// Breadcrumbs
// --------------------------------------------------


nav.breadcrumb {
	//@include clearfix;
	padding: $breadcrumb-padding-vertical $breadcrumb-padding-horizontal;
	list-style: none;
	background-color: $breadcrumb-bg;
	font-size: $breadcrumb-font-size;
	margin-bottom: 0;
	border-radius: 0;
	margin-left: -$grid-gutter-width/2;
	color: $breadcrumb-active-color;

	>a {
		display: inline-block;
		position: relative;
		line-height: $line-height-base;
		@include link-border-hover();
		@include margin-LR($grid-gutter-width/2,$grid-gutter-width/2);
		&:after {
			content: "#{$breadcrumb-separator}";
			position: absolute;
			//right: 0;
			right: -$grid-gutter-width/2;
			top: 50%;
			transform: translateY(-50%);
			padding: 0;
			color: $breadcrumb-color;
			margin-right: -1px;
		}
		&:last-child {
			margin-right: $grid-gutter-width;
		}
	}
}


