.accordion-wrap {
	.view-content {
		border-bottom: 1px solid $accordion-item-border-color;
		@include margin-TB(60px, 60px);
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
		@include media(mobile){
			@include margin-TB(30px, 30px);
		}
	}

	.views-row {
		margin: 0;
		border-top: 1px solid $accordion-item-border-color;
		&.open {
			.accordion-header {
				color: $accordion-item-open-title-color;
				span {
					border-bottom-color: transparent;
				}
				&:hover {
					&, &:after {
						color: #888f90;
					}
				}
				&:after {
					color: $accordion-item-open-caret-color;
					transform: rotate(-180deg);
					top: 25%;
				}
			}
		}
	}
	.accordion-header {
		@extend %h4;
		font-weight: $font-weight-medium;
		margin: 0;
		color: color-themify();
		@include link-border-hover(true, dashed);
		cursor: pointer;
		@include padding-TB($accordion-item-title-padding-vertical, $accordion-item-title-padding-vertical);
		padding-right: 50px;
		transition: transition-base(color);
		position: relative;

		&:after {
			content: $fa-var-angle-down;
			@include fa-icon;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			font-size: 32px;
			color: $accordion-item-caret-color;
			transition: transition-base(color), transition-base(transform), transition-base(top), $transition-fade;
		}
		@include media(mobile) {
			@include padding-TB($accordion-item-title-padding-vertical-xs, $accordion-item-title-padding-vertical-xs);
			&:after {
				font-size: 26px;
			}
		}
	}
	.accordion-body {
		&:not(.collapse) {
			height: 0;
		}
		transition: transition-base(height);
		overflow: hidden;
		> div {
			@include padding-TB(15px, 45px);
		}
		@include media(mobile) {
			> div {
				@include padding-TB(0, 10px);
			}
		}
	}

}