$c-00: #000000;
$c-8f: #8f8f8f;

$c-white: #ffffff;
$c-black: #000000;
$c-gray: #888f90;

$color-stack: (group: black, id: normal, color: #010101),
(group: black, id: light, color: #383a3d),
(group: gray, id: normal, color: #959595),
(group: gray, id: light, color: #8f8f8f),
(group: gray, id: dark, color: #606164),
(group: gray, id: darkest, color: #505252
) !default;

// Color  Function
@function color($group, $shade:normal, $transparency:1) {
	@each $color in $color-stack {
		$c-group: map-get($color, group);
		$c-shade: map-get($color, id);
		@if ($group == map-get($color, group) and $shade == map-get($color, id)) {
			@return rgba(map-get($color, color), $transparency);
		}
	}
}

// -- if you need change color in one theme
// -- add new variables
$c-theme-yellow: #f0b323;
$c-theme-gold: #cc9933;
$c-theme-green: #6b8f2e;
$c-theme-red: #b92f32;
$c-theme-gray-light: #a7a8aa;
$c-theme-gray: #6d6e72;
$c-theme-black: #383a3d;
$c-theme-blue: #03b2dc;
$c-theme-blue-dark: #0d5380;
$c-theme-violet: #7c4e9f;
$c-theme-violet-light: #a478bb;
//
$c-theme-red-johnson: #cc1e22;
$c-theme-green-amica: #005f58;

$c-theme-azure-blue: #008cfd;
$c-theme-banana: #ffc200;

//----------------------------------
$c-theme-active: 'default' !default;
//'linkHover': darken($color, 10%),
//'buttonHover': darken($color, 10%)
//because not use darken fn in color-themify fn
$color-themes: (
		'default': (
				'base': $c-theme-blue,
				'header': $c-theme-gray-light,
				'footer': $c-theme-black,
				'link': $c-theme-blue,
				'linkHover': darken($c-theme-blue, 10%),
				'button': $c-theme-blue,
				'buttonHover': darken($c-theme-blue, 10%)
		),
		'green': (
				'base': $c-theme-green,
				'header': $c-theme-green,
				'footer': $c-theme-black,
				'link': $c-theme-green,
				'linkHover': darken($c-theme-green, 10%),
				'button': $c-theme-green,
				'buttonHover': darken($c-theme-green, 10%)
		),
		'redBlack': (
				'base': $c-theme-red,
				'header': $c-theme-red,
				'footer': $c-theme-black,
				'link': $c-theme-red,
				'linkHover': darken($c-theme-red, 10%),
				'button': $c-theme-red,
				'buttonHover': darken($c-theme-red, 10%)
		),
		'blueWhite': (
				'base': $c-theme-blue,
				'header': $c-theme-blue-dark,
				'footer': $c-theme-black,
				'link': $c-theme-blue,
				'linkHover': darken($c-theme-blue, 10%),
				'button': $c-theme-blue,
				'buttonHover': darken($c-theme-blue, 10%)
		),
		'blueGold': (
				'base': $c-theme-gold,
				'header': $c-theme-blue-dark,
				'footer': $c-theme-black,
				'link': $c-theme-gold,
				'linkHover': darken($c-theme-gold, 10%),
				'button': $c-theme-gold,
				'buttonHover': darken($c-theme-gold, 10%)
		),
		'blueRedWhite': (
				'base': $c-theme-red,
				'header': $c-theme-blue-dark,
				'footer': $c-theme-black,
				'link': $c-theme-red,
				'linkHover': darken($c-theme-red, 10%),
				'button': $c-theme-red,
				'buttonHover': darken($c-theme-red, 10%)
		),
		'white': (
				'base': $c-theme-yellow,
				'header': $c-theme-gray,
				'footer': $c-theme-gray,
				'link': $c-theme-yellow,
				'linkHover': darken($c-theme-yellow, 10%),
				'button': $c-theme-yellow,
				'buttonHover': darken($c-theme-yellow, 10%)
		),
	//Special
		'cyberScoutCobranded': (
				'base': $c-theme-yellow,
				'header': $c-theme-yellow,
				'footer': $c-theme-black,
				'link': $c-theme-yellow,
				'linkHover': darken($c-theme-yellow, 10%),
				'button': $c-theme-yellow,
				'buttonHover': darken($c-theme-yellow, 10%)
		),
		'customTravelers': (
				'base': $c-theme-yellow,
				'header': $c-theme-yellow,
				'footer': $c-theme-black,
				'link': $c-theme-yellow,
				'linkHover': darken($c-theme-yellow, 10%),
				'button': $c-theme-yellow,
				'buttonHover': darken($c-theme-yellow, 10%)
		),
		'customAmica': (
				'base': $c-theme-yellow,
				'header': $c-theme-green-amica,
				'footer': $c-theme-black,
				'link': $c-theme-gold,
				'linkHover': darken($c-theme-gold, 10%),
				'button': $c-theme-gold,
				'buttonHover': darken($c-theme-gold, 10%)
		),
		'customJohnson': (
				'base': $c-theme-violet,
				'header': $c-theme-gray-light,
				'footer': $c-theme-black,
				'link': $c-theme-red-johnson,
				'linkHover': darken($c-theme-red-johnson, 10%),
				'button': $c-theme-red-johnson,
				'buttonHover': darken($c-theme-red-johnson, 10%)
		),
		'customADT': (
				'base': $c-theme-banana,
				'header': $c-theme-azure-blue,
				'footer': $c-theme-black,
				'link': $c-theme-banana,
				'linkHover': darken($c-theme-banana, 10%),
				'button': $c-theme-azure-blue,
				'buttonHover': darken($c-theme-azure-blue, 10%)
		)
) !default;

// Color Themify  Function
@function color-themify($key: 'base') {
	@each $theme, $colors in $color-themes {
		@if $theme == $c-theme-active {
			@return map-get($colors, $key);
		}
	}
}

