.featured-articles-wrap {
	$articles-content-padding-horizontal: 20px;
	$articles-content-padding-vertical: 20px;

	@mixin item-indents($TB, $LR) {
		.articles-item-content {
			padding: $articles-content-padding-vertical $articles-content-padding-horizontal;
		}
		.item-img {
			margin: (-$articles-content-padding-vertical) (-$articles-content-padding-horizontal) $articles-content-padding-vertical;
		}
		.item-logo {
			margin: $articles-content-padding-vertical (-$articles-content-padding-horizontal) (-$articles-content-padding-vertical);
			padding: $articles-content-padding-vertical $articles-content-padding-horizontal;
		}
	}

	.masonry-processed {
		@include start-animate(fadeIn, .6s);
	}

	.view-content {
		@include make-row;
		overflow: hidden; //
	}

	@include item-indents($articles-content-padding-vertical, $articles-content-padding-horizontal);
	.articles-item-content {
		@include margin-TB($grid-gutter-width/2, $grid-gutter-width/2);
		@include media(mobile) {
			@include margin-TB(8px, 8px);
		}
		position: relative;
		background-color: #f3f3f2;
		border: 1px solid #ebebeb;
		&:hover {
			.item-img img {
				transform: scale(1.2) translateZ(0);
			}
      .item-logo img{
        transform: scale(0.8) translateZ(0);
      }
		}

    >div {
      a {
        position: relative;
        z-index: 2;
      }
    }
	}

	//huck for item-date, item-category : because display: inline;
	& {
		.articles-item-content {
			line-height: 1.4;
		}
		.item-body {
			line-height: $line-height-base;
		}
	}

	.item-img,
	.item-logo {

		overflow: hidden;

		a {
			display: block;
			@include link-reset;

		}

		img {
			transform: translateZ(0);
			transition: transition-base(transform);
			//transition-timing-function: ease-in-out;
		}
	}

  .item-img {
    img {
      width: 100%;
      height: auto;
    }
  }

	.item-img-video {
		@extend .item-img;

		a {
			@include link-reset;
			display: block;
			min-height: 60px;
			background-color: gray;

			position: relative;
			&:after,
			&:before {
				content: "";
				position: absolute;
				z-index: 2;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				cursor: pointer;
			}

			&:before {
				width: 70px;
				height: 45px;
				background-color: $c-black;
				border-radius: 5px;
				opacity: .5;
			}
			&:after {
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 10px 0 10px 20px;
				border-color: transparent transparent transparent $c-white;
				transition: transition-base(opacity);
				margin-left: 4px;
			}
			&:hover {
				&:after {
					opacity: .5;
				}
			}
		}
	}

	.item-img-audio {
		color: rgba(#c3c5c5, .5);
		font-size: 36px;
	}

	.item-date,
	.item-category,
	.item-expert {
		@extend %metadata-style;
		padding-right: 10px;
		+ .item-date,
		+ .item-category,
		+ .item-expert {
			position: relative;
			&:before {
				content: "|";
				color: #dedede;
				padding-right: 10px;
				display: inline;
			}
		}

		@include media(mobile) {
			padding-right: 5px;
			+ .item-date,
			+ .item-category,
			+ .item-expert {
				&:before {
					padding-right: 5px;
				}
			}
		}
	}

  .link-overlay {
    a {
      position: absolute!important;
      opacity: 0!important;
      z-index: 1!important;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

	.item-date {
		white-space: nowrap;
	}

	.item-title {
		@include margin-TB(15px, 10px);
		&:first-child {
			margin-top: 0;
		}
	}

	.title {
		@extend %h6;
		font-weight: $font-weight-medium;
		margin-bottom: 0;
		a {
			position: relative;
			z-index: 3;
		}
	}

	.item-logo {
		background-color: $c-white;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: content-box;

		img {
            display: inline-block;
			width: auto;
			max-width: 100%;
			height: auto;
		}
	}

	//@include media(tablet-mobile) {
	//	.link-overlay {
	//		display: block;
	//		position: absolute;
	//		top: 0;
	//		left: 0;
	//		width: 100%;
	//		height: 100%;
	//		z-index: 2;
	//	}
	//}

	@include media(mobile) {
		$articles-content-padding-vertical: 15px;
		@include item-indents($articles-content-padding-vertical, $articles-content-padding-horizontal);

		//.articles-item {
		//	//@include margin-TB(10px, 10px);
		//}

		.articles-item-content {
			//@include padding-TB($articles-content-padding-vertical, $articles-content-padding-vertical);
		}

		//.item-img {
		//	margin-top: -$articles-content-padding-vertical;
		//}

		.item-title {
			@include margin-TB(10px, 5px);
		}

		//.item-logo {
		//	margin-bottom: -$articles-content-padding-vertical;
		//	//@include padding-TB($articles-content-padding-horizontal - 10);
		//}
	}

}













