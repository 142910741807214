.content-inner {
	position: relative;
	z-index: 1;

}

.section-gray {
	background-color: #f3f3f2;
}

.section {
	@include padding-TB(55px, 70px);

	@include media(mobile) {
		@include padding-TB(35px, 35px);
	}
}

.section-title {
	position: relative;
	z-index: 1;
	background: no-repeat center;
	@include background-size(cover);
	background-attachment: fixed;
	color: $c-white;
	text-align: center;
	font-weight: $font-weight-light;
	overflow: hidden;
	//display: flex;
	//align-items: center;
	//min-height: 405px;
	//.row {
	//	width: 100%;
	//}
	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#383a3d, .7);
		z-index: -1;
	}

	.bg-blur {
		overflow: hidden;
		-webkit-filter: blur(6px);
		-ms-filter: blur(6px);
		-moz-filter: blur(6px);
		-o-filter: blur(6px);
		filter: blur(6px);
		//filter: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">\
		//	<filter id="blur"><feGaussianBlur in="SourceGraphic" stdDeviation="6" /></filter></svg>#blur');
		//filter: progid: DXImageTransform.Microsoft.Blur(PixelRadius='6');
		@include media(mobile) {
			-webkit-filter: blur(3px);
			-ms-filter: blur(3px);
			-moz-filter: blur(3px);
			-o-filter: blur(3px);
			filter: blur(3px);
		}
	}

	&.section-title-banner {
		.bg-blur {
			-webkit-filter: blur(15px);
			-ms-filter: blur(15px);
			-moz-filter: blur(15px);
			-o-filter: blur(15px);
			filter: blur(15px);
			//filter: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">\
			//	<filter id="blur"><feGaussianBlur in="SourceGraphic" stdDeviation="15" /></filter></svg>#blur');
		}
	}


	.bg-section {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		background: no-repeat center;
		-webkit-background-size: cover;
		background-size: cover;
		@include media(desktop) {
			background-attachment: fixed;
		}
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: color(black, light);
			opacity: .3;
			z-index: -1;
		}
	}
	
	&.light {
		&.section-title {
			.container {
				color: black;
			}

			&:after {
				background-color: #fff;
				opacity: .7;
			}
		}

		.bg-section:after {
			background-color: #fff;
			opacity: .5;
		}
	}

	.content-inner {
		position: relative;
		z-index: 1;
	}

	@include media(tablet-mobile) {
		background-attachment: inherit;
	}

	.content-inner {

		@include padding-TB(125px, 110px);

	}

	.page-title,
	.title-section,
	h1, h2 {
		@extend %h1;
		font-weight: $font-weight-medium;
		margin-top: 0;
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0;
		}

	}

	@include h-all {
		color: inherit;
		font-weight: $font-weight-light;
	}

	.image-banner-wrapper {
		margin-bottom: 45px;
		box-shadow: 6px 0 10px rgba($c-black, .16);
		width: 75%;
		margin-right: auto;
		margin-left: auto;
	}

	@include media(mobile) {
		.content-inner {
			@include padding-TB(65px, 60px);
		}
	}
}

.section-title-main {
	.content-inner {
		@include padding-TB(135px, 120px);

	}
	.title-section,
	h1, h2 {
		font-weight: $font-weight-light;
	}
	@include media(mobile) {
		display: flex;
		align-items: center;
		.row {
			width: 100%;
		}
		min-height: 100vh;
		.content-inner {
			@include padding-TB(35px, 20px);
		}
	}
}

.section-content {
	@extend .section;
	.breadcrumb-block + & {
		padding-top: 25px;
	}

	@include h-all {
		color: $c-black;
	}

	.view-header {
		@include h-all {
			&:first-child {
				margin-top: 0;
			}
		}
	}

	.content-left-block {
		@include margin-TB(35px, 35px);
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
		@include media(mobile) {
			@include margin-TB(20px, 20px);
		}
	}

	.content {
		h1.title {
			@extend %h2;
			~ .content {
				margin-top: 25px;
				@include media(mobile) {
					margin-top: 10px;
				}
			}
		}
	}

	.field-item {
		&:first-child {
			@include h-all {
				&:first-child {
					margin-top: 0;
				}
			}
		}
	}
}

.region-content-top {
	@include h-all {
		&:first-child {
			margin-top: 0;
		}
	}
}

