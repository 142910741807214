.node-type-tips {
	//on detail
	.page-title {
		&:before {
			content: $fa-var-lightbulb-o;
			@include fa-icon;
			margin-right: 25px;
		}
	}

	.region-content {
		margin-top: 30px;
	}
}

.tips-list-wrap {
	$tips-img-width: 100px;
	$tips-xs-img-width: 80px;
	.view-content {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		@include padding-column-reset();
	}

	.tips-item {
		@include padding-column();
		width: (100%/3);
		flex-basis: (100%/3);
		max-width: (100%/3);
		position: relative;
		@include margin-TB($grid-gutter-width/2, $grid-gutter-width/2);
		display: flex;
		min-height: 136px;

		@include media(tablet) {
			flex-basis: (100%/2);
			max-width: (100%/2);
		}

		@include media(mobile) {
			flex-basis: 100%;
			max-width: 100%;
			min-height: 78px;
			@include margin-TB(7px, 7px);
		}
	}

	.link-overlay {
		position: absolute;
		z-index: 1;
		top: 0;
		left: $grid-gutter-width/2;
		right: $grid-gutter-width/2;
		height: 100%;
		span,
		a {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	.tips-item-content {
		display: flex;
		width: 100%;
		background-color: #f3f3f2;
		transition: transition-base(background-color);

		&:hover {
			background-color: color-themify();
			.tips-title {
				>h1, >h2, >h3, >h4, >h5, >h6 {
					color: $c-white;
					&:before {
						color: $c-white;
					}
				}
			}
			.item-img {
				opacity: .9;
				transform: scale(1.05) translateZ(0);
			}
		}
	}

	.views-field-body {
		width: $tips-img-width;
		flex: initial;
		//background-color: color-themify();
		.field-content {
			width: 100%;
			height: 100%;
			position: relative;
			overflow: hidden;
		}
		@include media(mobile){
			width: $tips-xs-img-width;
		}
	}
	.item-img {
		position: absolute;
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
		background: no-repeat center;
		-webkit-background-size: cover;
		background-size: cover;
		transition: transition-base(opacity), transition-base(transform);
		transform: scale(1) translateZ(0);
	}

	.tips-title {
		h1, h2, h3, h4, h5, h6 {
			@extend %h5;
			margin: 0;
			color: color(gray, darkest);
			font-weight: $font-weight-medium;
		}
		margin: 0;
		flex: 1;
		display: flex;
		align-items: center;
		padding: 20px;
		>h1, >h2, >h3, >h4, >h5, >h6 {
			display: inline-block;
			padding-left: 30px;
			position: relative;
			&:before {
				content: $fa-var-lightbulb-o;
				@include fa-icon;
				position: absolute;
				top: 0;
				left: 0;
				font-size: 27px;
				color: color-themify();
				transform: translateY(-10%);
				transition: transition-base(color, 0s);
			}
		}
	}
}
