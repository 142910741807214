.social-links {
	a {
		display: inline-block;
		padding: 10px;
		text-align: center;
		@include link-reset;

		min-width: $social-width;
		font-size: $social-font-size;
		line-height: $social-font-size;
		.fa-facebook {
			font-size: $social-font-size*0.9;
		}
		color: $social-link-color;
		transition: $transition-fade;
		&:hover {
			color: color-themify('link');
		}
		@include media(mobile) {
			font-size: $social-xs-font-size;
			.fa-facebook {
				font-size: $social-xs-font-size*0.9;
			}
		}
	}
}

.addtoany_list.a2a_kit_size_32 {
	line-height: 0 !important;
	color: #888f90;
	font-size: 16px;
	text-transform: uppercase;
	display: block;

	span{
		display: inline-block;
		vertical-align: middle;
	}

	@include media(mobile) {
		font-size: 12px;
		line-height: 25px;
	}

	> span + a {
		margin-left: 10px;
	}

	a {
		padding: 0;
		margin-left: 5px;
		margin-right: 5px;
		> span {
			transition: transition-base(background-color);
			border-radius: 5px;
		}

		&:hover {
			> span {
				background-color: color-themify() !important;
			}
		}
		@include media(mobile) {
			> span {
				width: 25px;
				height: 25px;
				line-height: 25px;
				svg {
					transform: scale(.8);
				}
			}
		}
	}
}
