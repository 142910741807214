.view-resources {
	$resource-logo-width:68px;
	$resource-logo-width-xs:40px;
	.view-grouping {
		+ .view-grouping {
			margin-top: 40px;
		}
	}

	.view-grouping-header {
		overflow: hidden;
		position: relative;
		text-align: center;
		margin-bottom: 40px;
		&:after, &:before {
			content: '';
			display: inline-block;
			vertical-align: middle;
			box-sizing: border-box;
			width: 100%;
			height: 1px;
			background: $border-color-default;
		}
		img {
			display: inline-block;
			width: 45px;
			height: auto;
			@include margin-LR(15px, 15px);
		}
		&:after {
			margin-right: -100%;
		}
		&:before {
			margin-left: -100%;
		}
	}

	.view-grouping-content {
		text-align: center;
		@include padding-column-reset();
		display: flex;
		flex-wrap: wrap;
	}

	.title-group {
		@include padding-column();
		width: 100%;
		margin-bottom: 25px;
		margin-top: 0;
	}

	.resources-item {
		@include clearfix;
		text-align: left;
		@include padding-column();
		flex-basis: 50%;
		max-width: 50%;
		@include margin-TB(20px, 20px);
		&:nth-child(odd) {
			padding-right: 50px;
		}
		&:nth-child(even) {
			padding-left: 50px;
		}
	}

	.views-field-field-icon-resources {
		width: $resource-logo-width;
		float: left;

		a {
			display: inline-block;
		}
		img {
			width: 100%;
			height: auto;
		}
	}

	.views-field-field-icon-resources ~ .views-field-title,
	.views-field-field-icon-resources ~ .views-field-body {
		margin-left: $resource-logo-width + 30px;
	}

	.views-field-title {
	}
	.views-field-body {
	}
	.title {
		@extend %h4;
		margin-bottom: 10px;
		color: color-themify();
		&:hover {
			color: color-themify(linkHover);
		}
		a {
			@include link-border-hover();
		}
	}
	@include media(tablet-mobile){
		.view-grouping {
			+ .view-grouping {
				margin-top: 20px;
			}
		}
		.resources-item {
			flex-basis: 100%;
			max-width: 100%;
			&:nth-child(odd),
			&:nth-child(even){
				@include padding-column();
			}

		}
		.view-grouping-header {
			margin-bottom: 20px;
		}
		.title {
			margin-bottom: 5px;
		}
		.title-group {
			margin-bottom: 15px;
		}
	}
	@include media(mobile){
		.resources-item {
			@include margin-TB(10px, 10px);
		}
		.views-field-field-icon-resources {
			width: $resource-logo-width-xs;
		}
		.views-field-field-icon-resources ~ .views-field-title,
		.views-field-field-icon-resources ~ .views-field-body {
			margin-left: $resource-logo-width-xs + 15px;
		}
	}
}
