//
// Variables
// --------------------------------------------------

//== Media Queries
$screen-xs:                  480px;
$screen-xs-min:              $screen-xs;
$screen-phone:               $screen-xs-min;

$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
$screen-tablet:              $screen-sm-min;

$screen-md:                  992px;
$screen-md-min:              $screen-md;
$screen-desktop:             $screen-md-min;

$screen-lg:                  1200px;
$screen-lg-min:              $screen-lg;
$screen-lg-desktop:          $screen-lg-min;

$screen-xxs-max:             ($screen-xs-min - 1);
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);

$media-stack:   (id: desktop-lg, rule: '(min-width: #{$screen-lg-min})'),
				(id: desktop, rule: '(min-width: #{$screen-md-min})'),
				(id: tablet-desktop, rule: '(min-width: #{$screen-sm-min})'),
				(id: tablet, rule: '(min-width: #{$screen-sm-min}) and (max-width:#{$screen-sm-max})'),
				(id: tablet-mobile, rule: '(max-width:#{$screen-sm-max})'),
				(id: mobile, rule: '(max-width: #{$screen-xs-max})'),
				(id: mobile-md, rule: '(max-width: 567px)'),
				(id: mobile-xs, rule: '(max-width: #{$screen-xxs-max})'),
				(id: print, rule: 'only print');

//== Transition
$cubic-bezier:               cubic-bezier(0.30, 1.40, 0.70, 1);
$ease-out-quad:              cubic-bezier(0.30, 1.40, 0.70, 1);
$transition-default:         .3s ease-out;

$transition-base:            all $transition-default;
$transition-fade:            opacity .15s linear;
$transition-collapse:        height .35s ease;

//== Typography
$text-color:                color(grey, darkest);//$gray-dark !default;
$link-color:                color-themify('link');// $brand-primary !default;
$link-hover-color:          color-themify('linkHover');//darken($link-color, 15%) !default;
$link-hover-decoration:     none;//underline !default;

$font-family-roboto:        'Roboto', sans-serif;
$font-family-base:          $font-family-roboto;//$font-family-sans-serif !default;

$font-size-base:            18px; //14px !default;
$font-size-xs-base:         14px;

$text-small-font-size:      16px;
$text-small-xs-font-size:   $font-size-xs-base;

$font-size-h1:              floor(($font-size-base * 2.56)); // ~46px //floor(($font-size-base * 2.6)) !default; // ~36px
$font-size-h2:              floor(($font-size-base * 2.23)); // ~40px //floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3:              ceil(($font-size-base * 1.66)); // ~30px  //ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4:              ceil(($font-size-base * 1.33)); // ~24px  //ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5:              ceil(($font-size-base * 1.1)); // ~20px  //$font-size-base !default;
$font-size-h6:              $font-size-base !default; //ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-xs-h1:            floor(($font-size-xs-base * 1.79)); // ~25px
$font-size-xs-h2:            floor(($font-size-xs-base * 1.64)); // ~23px
$font-size-xs-h3:            ceil(($font-size-xs-base * 1.29)); // ~18px
$font-size-xs-h4:            ceil(($font-size-xs-base * 1.07)); // ~15px
$font-size-xs-h5:            ceil(($font-size-xs-base * 1.07)); // ~15px
$font-size-xs-h6:            $font-size-xs-base;

$font-weight-regular:        400;
$font-weight-light:          300;
$font-weight-medium:         500;
$font-weight-semibold:       500;
$font-weight-bold:           600;

$font-weight-base:           $font-weight-light;

$line-height-base:            1.666666666666667;//30/18 //1.428571429 !default; // 20/14

$headings-font-weight:        400; //500 !default;
$headings-line-height:        1.3; //1.1 !default;
$headings-color:              $c-black;//inherit !default;

$border-color-default:		rgba(#c3c5c5,.3);

//== Components
$component-active-color:    rgba($c-white, .5);//#fff !default;
$component-active-bg:       transparent;//$brand-primary !default;

//== Dropdowns

$dropdown-color:                 $c-white;
$dropdown-bg:                    color(black, light);//#fff !default;
$dropdown-border:                transparent;//rgba(0,0,0,.15) !default;
$dropdown-fallback-border:       transparent;//#ccc !default;
$dropdown-divider-bg:            rgba($c-white, .05);//#e5e5e5 !default;

$dropdown-link-color:            $c-white;//$gray-dark !default;
$dropdown-link-hover-color:      $dropdown-link-color;//darken($gray-dark, 5%) !default;
$dropdown-link-hover-bg:         color-themify('link');//#f5f5f5 !default;
$dropdown-link-active-color:     rgba($c-white, .5);
$dropdown-link-active-bg:      	transparent;
$dropdown-link-disabled-color:   rgba($c-white, .5);//$gray-light !default;
$dropdown-header-color:          $dropdown-link-color;//$gray-light !default;
$dropdown-caret-color:           $dropdown-link-color;//#000 !default;
$dropdown-link-padding:          18px 20px;//10px 15px !default;

$dropdown-xs-link-hover-bg:      #323437;

//== Grid system
$grid-gutter-width:             30px;//30px !default;

$container-large-desktop:       (1140px + $grid-gutter-width) !default;
$grid-float-breakpoint:       $screen-md-min;//$screen-sm-min !default;


$container-lg:                  $container-large-desktop !default;
$container-desktop:             (1140px + $grid-gutter-width);//(940px + $grid-gutter-width) !default;
$container-sm:                  $container-lg;//$container-tablet !default;
$container-md:                  $container-lg;//$container-desktop !default;

//$container-sm-padding-horizontal:          45px;
$container-sm-padding-horizontal:          15px;


//== Navbar
$navbar-height:                        56px;//50px !default;
$navbar-height-item-sm:                70px;
$navbar-height-item-xs:                45px;

$nav-link-padding:                     10px 20px;//10px 15px !default;
$navbar-padding-sm-horizontal:         20px;
$navbar-padding-sm-vertical:           30px;
$navbar-padding-xs-horizontal:         8px;
$navbar-padding-xs-vertical:           10px;

$navbar-divider-color:                 rgba($c-white, .3);

$navbar-backdrop:                      rgba($c-black, .5);
$navbar-caret-width:                   60px;

//== Header
$header-top-bg:                 #fff;
$header-top-color:              color(gray, light);
$header-top-link-color:         color(gray, light);
$header-top-link-hover-color:   color-themify('link');

$header-top-height:             100px;
$header-top-height-xs:          50px;

$header-top-padding-vertical:       10px;
$header-top-padding-vertical-xs:    0px;

$nav-sidebar-width:                    390px;
$nav-sidebar-color:                    $c-white;
$nav-sidebar-font-size:                20px;
$nav-sidebar-font-weight:              $font-weight-medium;

$nav-sidebar-padding-left:              30px;
$nav-sidebar-padding-right:             20px;
$nav-sidebar-link-vertical:             20px;

$nav-sidebar-padding-horizontal-xs:       10px;
$nav-sidebar-link-vertical-xs:          12px;

$nav-sidebar-divider-bg:                rgba($c-white, .3);

$nav-sidebar-title-height:              $header-top-height - 10;
$nav-sidebar-title-font-size:           30px;
$nav-sidebar-title-text-transform:      uppercase;

$nav-sidebar-xs-width:                  250px;
$nav-sidebar-xs-font-size:              14px;
$nav-sidebar-xs-padding-horizontal:     10px;

$nav-sidebar-xs-title-height:           $header-top-height-xs + 10;
$nav-sidebar-xs-title-font-size:        18px;

$nav-sidebar-toggle-width:              $nav-sidebar-title-height;
$nav-sidebar-toggle-xs-width:           $nav-sidebar-xs-title-height;

//== Footer
$footer-color:              color(gray,light);
$footer-font-size:          14px;

$footer-xs-font-size:       11px;

//== Social
$social-width:             50px;
$social-font-size:         35px;
$social-link-color:        inherit;
$social-xs-font-size:      22px;

//== Breadcrumbs
$breadcrumb-padding-vertical:   20px !default;
$breadcrumb-padding-horizontal: 0;//15px !default;
$breadcrumb-bg:                 transparent;//#f5f5f5 !default;
$breadcrumb-color:              #e6ebed;//#ccc !default;
$breadcrumb-active-color:       #888f90;//$gray-light !default;
$breadcrumb-separator:          "|";// "/" !default;
$breadcrumb-font-size:          16px;

//== Modal
//$modal-inner-padding:         15px !default;
$modal-title-padding:         	20px 60px;//15px !default;
//$modal-title-line-height:     $line-height-base !default;
//$modal-content-bg:                             #fff !default;
//$modal-content-border-color:                   rgba(0,0,0,.2) !default;
//$modal-content-fallback-border-color:          #999 !default;

//$modal-backdrop-bg:           #000 !default;
$modal-backdrop-opacity:      .6;//.5 !default;

$modal-header-bg:   		#383a3d;
$modal-header-color:   		#ffffff;

$modal-header-border-color:   transparent;//#e5e5e5 !default;
//$modal-footer-border-color:   $modal-header-border-color !default;
//
$modal-lg:                    	$container-large-desktop;//900px !default;
//$modal-md:                    600px !default;
//$modal-sm:                    300px !default;



//== Slider
$slider-dot-margin:                  	20px;
$slider-dot-width:                   	12px;
$slider-dot-item-width:              	$slider-dot-width + $slider-dot-margin;
$slider-dot-color:                   	#c3c5c5;
$slider-dot-active-color:            	color-themify();
$slider-dot-hover-color:             	rgba($slider-dot-color,.7);
$slider-dot-border-radius:           	50%;
$slider-dots-padding-vertical:       	35px;

$slider-padding-for-arrow:     			75px;
$slider-arrow-color:                 	$slider-dot-color;
$slider-arrow-hover-color:           	color-themify();
$slider-arrow-disabled-color:        	rgba($slider-arrow-color,.2);

//== Accordion
$accordion-item-border-color: 					$border-color-default;
$accordion-item-title-padding-vertical: 		18px;
$accordion-item-title-padding-vertical-xs: 		12px;
$accordion-item-title-color: 					color-themify();
$accordion-item-caret-color: 					$accordion-item-title-color;
$accordion-item-open-title-color: 				color(gray, darkest);
$accordion-item-open-caret-color: 				#c3c5c5;

//== Forms
//
//##

//** `<input>` background color
//$input-bg:                       #fff !default;
//** `<input disabled>` background color
$input-bg-disabled:              #f3f3f2;//$gray-lighter !default;
$input-color:                    $text-color;//$gray !default;
$input-border:                   #ebebeb;//#ccc !default;
$input-border-radius:            0;//$border-radius-base !default;
$input-border-focus:             #cecece;//#66afe9 !default;
$input-color-placeholder:        $text-color;//#999 !default;
$input-font-size:        		 $font-size-base;
$input-font-weight:        		 $font-weight-base;
$input-padding-vertical:    	 5px;
$input-padding-horizontal:    	 20px;

$input-xs-font-size:        	$font-size-xs-base;
$input-xs-padding-horizontal:    	15px;

$input-xs-font-size:        		 $font-size-xs-base;
$input-height-base:              	54px;//($line-height-computed + ($padding-base-vertical * 2) + 2) !default;
$input-xs-height-base:              35px;//($line-height-computed + ($padding-base-vertical * 2) + 2) !default;
//$input-height-large:             (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2) !default;
//$input-height-small:             (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2) !default;




//== bootstrap-select
$select-border-radius: 						0;
$select-border-color: 						#ebebeb;
$select-color: 								#6d6e72;
$select-font-size: 							inherit;
$select-font-weight: 						$font-weight-light;
$select-padding-horizontal: 				$input-padding-horizontal;
$select-padding-vertical: 					$input-padding-vertical;
$select-caret-width: 						50px;
$select-caret-color: 						#6d6e72;
$select-caret-active-color: 				color-themify('link');

$select-xs-padding-horizontal: 				$input-xs-padding-horizontal;
$select-xs-padding-vertical: 				$input-padding-vertical;

$form-dropdown-bg: 							#ffffff;
$form-dropdown-border: 						$select-border-color;
$form-dropdown-divider-bg: 					$select-border-color;

$form-dropdown-link-color: 					$select-color;
$form-dropdown-link-hover-color: 			$select-color;
$form-dropdown-link-hover-bg: 				#f3f3f2;
$form-dropdown-link-active-color: 			color-themify('link');
$form-dropdown-link-active-bg: 				transparent;
$form-dropdown-link-disabled-color: 		rgba($select-color, .5);
//$form-dropdown-link-padding: 				15px $input-padding-horizontal;
$form-dropdown-link-padding:				15px $input-padding-horizontal;
$form-dropdown-box-shadow: 					0 5px 12px rgba(0, 0, 0, .1);
$form-dropdown-dropup-box-shadow: 			0 -5px 12px rgba(0, 0, 0, .1);

$form-dropdown-xs-link-padding:				9px $input-xs-padding-horizontal;

$ajax-progress-size: 						20px;

$form-error-color:              red;

// ***********************************************************************************************
// Libs
// ***********************************************************************************************

//----------------
// Fontawesome
//----------------
$fa-font-path:              "../fonts/fontawesome/"; //'../fonts/' rewrite
$fa-font-size-base:         $font-size-base; //14px rewrite










