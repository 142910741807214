html,
body {
	height: 100%;
	//-webkit-tap-highlight-color: transparent;
	//-webkit-touch-callout: none;
	//min-height: 100%;
	//-webkit-text-size-adjust: 100%;
	//-ms-text-size-adjust: 100%;
	//-webkit-font-smoothing: antialiased;
	//-moz-osx-font-smoothing: grayscale;
}

body {
	min-width: 320px;
}

html.menu-open {
	&, body {
		overflow-y: hidden;
		overflow-x: auto;
		position: relative;
		height: 100%;
	}
}

.page {
	height: 100%;
	width: 100%;
	display: table;
	table-layout: fixed;
}

.wrap {
	width: 100%;
	height: 100%;
	display: table-row;
}

.footer {
	width: 100%;
	display: table-row;
}

.container {
	max-width: 100%;
	//@include media(tablet) {
	//	@include padding-LR($grid-gutter-width, $grid-gutter-width);
	//}
}

.container-md {
	@include media(desktop) {
		width: $container-desktop;
		max-width: 100%;
		@include margin-LR(auto, auto);
	}
}

body .page-content {
	//body because has page body with class page-content
	@include media(tablet) {
		padding-top: $header-top-height;
	}
	@include media(mobile) {
		padding-top: $header-top-height-xs;
	}
}

.views-row {
	@include margin-TB(15px, 15px);
}

.region-content {
	margin-top: 45px;
	&:first-child {
		margin-top: 0;
	}
	@include media(mobile) {
		margin-top: 30px;
	}
}

.region-content-top + .region-content,
.region-content-bottom {
	margin-top: 45px;
	@include media(mobile) {
		margin-top: 30px;
	}
}

.region-content-bottom {
	.block {
		margin-top: 50px;
		&:first-child {
			margin-top: 0;
		}
		@include media(mobile) {
			margin-top: 15px;
		}
	}
}

.section-bottom-full_gray {
	@include padding-TB(50px, 50px);
	background: #f3f3f2 no-repeat center right;

	.page-privacy-policy & {
		background-image: url('../images/pattern_map.png');
		background-position: center right 13%;
	}
	@include media(mobile){
		@include padding-TB(25px, 25px);
	}
}













