.view-id-blog {
    $blog-img-width: 165px;

    .page-title {
        margin-bottom: 20px;
    }

    .view-content > div:not(.field-type-image) {
        @include margin-TB(60px, 60px);
        @include media(mobile) {
            @include margin-TB(30px, 30px);
        }
        &:first-child {
            margin-top: 0;
        }
    }

    .item-img {
        width: 150px;
        margin-bottom: 15px;
    }

    //meta
    & {
        .item-date,
        .item-expert {
            @extend %metadata-style;
            position: relative;
            padding-right: 10px;
            + .item-date,
            + .item-expert {
                &:before {
                    content: "|";
                    color: #dedede;
                    padding-right: 10px;
                    display: inline;
                }
            }
        }

        .item-expert {
            color: color-themify(base);
        }
    }

    .item-title {
        @include margin-TB(5px, 5px);
    }

    .title {
        @extend %h6;
        font-weight: bold;
    }

    .item-body {
        p {
            margin-bottom: 0;
            + p {
                margin-top: 5px;

            }
        }
    }

    @include media(tablet-desktop) {
        .item-img {
            margin-top: 10px;
            float: left;
            width: $blog-img-width;
            height: $blog-img-width;
            position: relative;
            overflow: hidden;

            a {
                position: absolute;
                left: 50%;
                top: 0;
                transform: translateX(-50%);
                width: 230px; //huck for square img
                z-index: 2;
            }
        }

        .item-img ~ div {
            margin-left: $blog-img-width + 30px;
        }

        .item-img {

            + .item-date, + .item-expert {
                margin-left: 30px;

                + .item-expert, + .item-expert {
                    margin-left: 0;
                }

            }

        }

        /*.item-date,
        .item-expert {
            margin-left: 30px !important;
            + .item-date,
            + .item-expert {
                margin-left: 0 !important;
            }
        }*/

        .item-title {
            @include margin-TB(10px, 10px);
        }
    }
}

.blog-content-detail, .view-content {

    > .field-type-image {
        width: 300px;
        float: left;
        margin: 5px 30px 30px 0;
        img {
            width: 100%;
            height: auto;
        }
        @include media(mobile) {
            float: none;
            margin-bottom: 15px;
        }
    }

    .field-item {

        img.wp-post-image {
            width: 300px !important;
            margin: 10px 30px 30px 0;
            padding: 0 !important;
        }

    }

}









