.sidebar-right {
	font-size: 16px;
	color: #888f90;

	& {
		float: right;
		width: 25%;
		padding-left: $grid-gutter-width/2;


		margin-top: -87px; //huck
		.admin-menu & {
			margin-top: 0;
		}

		+ .region-content {
			padding-right: 45px;
			width: 75%;
			float: left;
		}

		@include media(tablet-mobile) {
			display: none;
			+ .region-content {
				padding-right: 0;
				width: auto;
				float: none;
			}

		}
	}

	.item-list ul {
		@include ul-reset;
		li {
			margin: 0;
			margin-top: 15px;
		}
	}

	a {
		color: inherit;

		&:hover,
		&.active {
			color: color-themify(base);
		}
	}

	.view-header {
		.title {
			@extend %h3;
			margin-bottom: 40px;
		}
	}


}