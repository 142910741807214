.row-flex {
    @include row-flex();
    @include padding-column-reset();
}

.row-flex-wrap {
    @extend .row-flex;
    flex-wrap: wrap;
}

.container {
    + .container {
        margin-top: 40px;
        @include media(mobile) {
            margin-top: 20px;
        }
    }
}