$btn-disabled-color: #cecece;
$btn-fa-indent: 20px;
$btn-fa-indent-animation: 10px;
$btn-fa-transition: $transition-default;

$btn-fill-default-color: color-themify('button');
$btn-fill-default-hover-color: color-themify('buttonHover');
$btn-fill-gray-color: #cecece;

%btn {
	display: inline-block;
	margin-bottom: 0; // For input.btn

	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background: none;
	white-space: nowrap;
	user-select: none;
	@include link-reset;
	outline: 0 none;
	border-radius: 60px;

	border: 2px solid currentColor;
	font-size: 20px;
	font-weight: $font-weight-medium;
	line-height: 20px;
	padding: 18px 55px;

	@include media(mobile) {
		font-size: 15px;
		line-height: 18px;
		padding: 10px 30px;
	}

	&.disabled,
	&[disabled] {
		cursor: default;
	}

	transition-property: border-color, background-color, color, opacity;
	transition-duration: .4s;
	transition-timing-function: $transition-default;

	@extend %btn-icon-right;
	.fa {
		position: relative;
		left: 0;
		transition: left $btn-fa-transition;
		vertical-align: middle;
	}
}

//== Style Btn
%btn-border {
	//color:inherit;
	//border-color: currentColor;
	//opacity: 1;
	//&:hover {
	//	opacity: .7;
	//}

}

@mixin btn-style($type:fill, $color: default) {
	@if ($type == border) {
		@if ($color == gray) {
			color: $btn-fill-gray-color;
			border-color: $btn-fill-gray-color;
			&:hover {
				color: darken($btn-fill-gray-color, 10%);
				border-color: darken($btn-fill-gray-color, 10%);
			}
		} @else {
			color: color-themify('link');
			border-color: color-themify('link');
			&:hover {
				color: color-themify('linkHover');
				border-color: color-themify('linkHover');
			}
		}
	} @else {
		color: $c-white;
		@if ($color == gray) {
			background-color: $btn-fill-gray-color;
			&:hover {
				background-color: darken($btn-fill-gray-color, 10%);
			}
		} @else {
			//color: color-themify();
			background-color: color-themify('link');

			&:hover {
				//color: color-themify('linkHover');
				background-color: color-themify('linkHover');
			}
		}
	}
}

%btn-fill {
	@include btn-style();
}

//== Disabled Btn
%btn-disabled-fill {
	cursor: default;
	&, &:hover {
		background-color: $btn-disabled-color;
	}
}

%btn-disabled-border {
	&, &:hover {
		border-color: $btn-disabled-color;
		color: $btn-disabled-color;
	}
	&:hover {
		.fa {
			left: 0;
		}
	}
}

//== Icon include Btn

%btn-icon-left {
	.fa {
		margin-left: 0;
		margin-right: $btn-fa-indent
	}
	&:hover {
		.fa {
			left: -$btn-fa-indent-animation;
		}
	}
}

%btn-icon-right {
	.fa {
		margin-right: 0;
		margin-left: $btn-fa-indent;
	}
	&:hover {
		.fa {
			left: $btn-fa-indent-animation;
		}
	}
}

%btn-clear {
	background: transparent;
	border: 0 none;
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background: none;
	white-space: nowrap;
	user-select: none;
	text-decoration: none;

	&:hover, &:focus {
		text-decoration: none;
	}
	outline: 0 none;

	&.disabled,
	&[disabled] {
		cursor: default;
	}

	transition-property: border-color, background-color, color;
	transition-duration: .4s;
}

.btn {
	@extend %btn;

	&.btn-border {
		//@extend %btn-border;
		@include btn-style(border, gray);
		&.disabled {
			@extend %btn-disabled-border;
		}
	}

	&.btn-fill {
		@extend %btn-fill;

		&.disabled {
			@extend %btn-disabled-fill;
		}
	}

	&.btn-icon-left {
		@extend %btn-icon-left;
	}
	&.btn-icon-right {
		@extend %btn-icon-right;
	}
}

.btn-load-more {
	width: 320px;
	max-width: 100%;
	@extend %btn;
	color: #cecece;
	border-color: #ebebeb;
	&:hover {
		background-color: #f3f3f2;
	}
	&:before {
		color: transparent;
		transition: transition-base(color);
	}

	&.progress-disabled {
		position: relative;
		text-indent: -9999px;
		&:before {
			content: "Loading...";
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			text-indent: 0;
			color: #cecece;
		}
	}
}

.btn-clear {
	@extend %btn-clear;
}

.btn-row {
	margin-top: 30px;
	@include media(mobile) {
		margin-top: 15px;
	}
}

