/**
 * Footer
 * --------------------------------------------------
 */

.footer {
	& {
		background-color: color-themify('footer');

		color: $footer-color;
		font-size: $footer-font-size;

		.row {
			@include padding-TB(50px, 50px);
		}

		.footer-item {
			@include padding-column();
		}

		@include media(desktop) {
			.row {
				@include row-flex;
				align-items: center;
				justify-content: flex-start;
			}
			.footer-item {
				order: 2;
				&:first-child {
					order: 3;
				}
				&:last-child {
					order: 1;
					align-self: flex-start;
				}
			}
			.footer-content {
				align-self: flex-start;
				width: 33%;
				@include media(desktop-lg) {
					width: 40%;
				}
			}
			.footer-nav {
				flex: 1;
			}
		}

		@include media(tablet-mobile) {
			text-align: center;

			.footer-item {
				&:first-child {
					margin-top: 0;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		@include media(tablet) {
			.row {
				@include padding-TB(25px, 50px);
			}
			.footer-item {
				@include margin-TB(30px, 30px);
			}
		}

		@include media(mobile) {
			font-size: $footer-xs-font-size;
			.row {
				padding: 20px 10px 30px;
			}
			.footer-item {
				@include margin-TB(15px, 15px);

			}
		}
	}

	.social-links {
		a {
			@include fade-hover($c-white, .76);
		}
	}

	.logo {
		width: 200px;
		@include media(desktop-lg) {
			width: 235px;
		}
		@include media(tablet-mobile) {
			@include margin-LR(auto, auto);
		}
		@include media(mobile) {
			width: 120px;
		}
	}

	.footer-nav {
		.menu {
			display: inline-block;
			> li {
				display: inline-block;
				position: relative;
				margin-right: 21px;
				&:after {
					content: "|";
					position: absolute;
					right: -15px;
					top: 50%;
					transform: translateY(-50%);
					color: #525457;
				}
				&:last-child {
					margin-right: 0;
					&:after {
						display: none;
					}
				}
				&.active-trail {
					a {
						&,
						&:hover {
							cursor: default;
							color: color-themify('linkHover');
							border-bottom-color: transparent;

						}
					}
				}

				@include media(mobile) {
					margin-right: 11px;
					&:after {
						right: -9px;
					}
				}
			}
		}

		a {
			display: inline-block;
			@include link-border-hover();
			//padding: 10px;
		}
	}
}
